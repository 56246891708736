import LocalDatabaseHandler from "../../appxolo-engine/modules/database/LocalDatabaseHandler";
import { getDatabase } from "../../Providers/local-databases";
import api from "../../Services/Api/api";

import { store } from "../../store";
import NodeData from "../../appxolo-engine/modules/calculation/execution/NodeData";
import AutoTrigger from "../../appxolo-engine/modules/triggers/AutoTrigger";
import utils from "../utils/utils";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import { getSyncDatabase } from "../../Providers/dbsync-module";

const databases = {};

class LocalDatbaseSubscription {
  lastTriggerTime = {};
  timeoutIds = {};

  subcribedFuncs = {}; // {randomId: func}

  subscribe = (func) => {
    if (typeof func != "function") return null;

    let id = Date.now().toString() + Math.random();
    this.subcribedFuncs[id] = func;
    return id;
  };

  unsubscribe = (id) => {
    if (!id) return;

    if (!this.subcribedFuncs[id]) {
      return;
    } else {
      delete this.subcribedFuncs[id];
    }

    return;
  };

  dispatchToSubcribers(data) {
    let funcs = Object.values(this.subcribedFuncs);

    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];

      try {
        if (func && typeof func === "function") {
          func(data);
        }
      } catch (e) {
        console.info(e);
      }
    }
  }

  onLocalTableDataChange(data) {
    const { tableName, dbId } = data;

    const key = `${dbId}-${tableName}`;
    const now = Date.now();

    if (!this.lastTriggerTime[key] || now - this.lastTriggerTime[key] >= 300) {
      this.lastTriggerTime[key] = now;

      // global.io.emit("/changeStream/table", data);
      this.dispatchToSubcribers(data);
    } else {
      if (this.timeoutIds[key]) {
        clearTimeout(this.timeoutIds[key]);
      }

      this.timeoutIds[key] = setTimeout(() => {
        this.dispatchToSubcribers(data);
      }, 300 - (now - this.lastTriggerTime[key]));
    }
  }
}
const localDatbaseSubscription = new LocalDatbaseSubscription();

const processDbSync = (x) => {
  const syncDatabase = getSyncDatabase();
  if (syncDatabase) syncDatabase.processLocalDbSync(x);
};

const localDatabaseHandler = new LocalDatabaseHandler({
  getDatabase,
  api,
  processDbSync: (x) => processDbSync(x),
});

const localDbChannel = new BroadcastChannel('local-database-channel');

localDbChannel.onmessage = (event) => {
  console.log('Message received:', event.data);
  localDatbaseSubscription.onLocalTableDataChange(event.data);
};

const onLocalTableDataChange = (data) => {
  localDatbaseSubscription.onLocalTableDataChange(data);
  localDbChannel.postMessage(data);
}

const databaseModule = {
  getDataBaseInfo: async ({dbId}) => {
    if (!databases[dbId]) {
      databases[dbId] = await api
        .socket(
          "v1/database/info",
          { dbId },
          { cache: { useCache: true, expiry: 1000 * 60 * 60 } }
        )
        .then((x) => x.database);
    }

    return databases[dbId];
  },

  write: async payload => {
    const database = await databaseModule.getDataBaseInfo({dbId: payload.dbId});
    const tableName = database.tables?.find(x => x._id === payload.tableId)?.name;

    if (database?.type === 'local') {
      const result = await localDatabaseHandler.write({
        ...payload,
        tableName,
        database,
      });

      const records =
      typeof result === "object"
        ? result instanceof Array
          ? result
          : [result]
        : null;

      if (payload.enableDbTrigger && records.length) {
        const state = store?.getState();
        const projectData = state.pState.APP?.projectData;

        const data = {
          records,
          triggerOn: {
            addRecord: "create",
            editRecord: "update",
            deleteRecord: "delete",
          }[payload.valueType],
          activeProjectId: projectData._id,
          dbId: database._id,
          tableName,

          database,
          projectData,
        };

        await processTriggers(data).catch((e) =>
          console.log("Error processing triggers: ", e.message)
        );
      }

      onLocalTableDataChange({
        event: payload.valueType,
        tableName,
        tableId: payload.tableId,
        dbId: payload.dbId,
      });

      return { records };
    } else {
      return api.socket("v1/database/write", payload);
    }
  },

  read: async (payload, options) => {
    const database = await databaseModule.getDataBaseInfo({
      dbId: payload.dbId,
    });

    if (database?.type === "local") {
      return localDatabaseHandler.read({
        ...payload,
        tableName: database.tables?.find((x) => x._id === payload.tableId)
          ?.name,
        database,
      });
    } else {
      return api.socket("v1/database/read", payload, options);
    }
  },

  localDatbaseSubscription,
};

const processTrigger = async (trigger, data) => {
  const setScreenState = (obj, persist = false, screenName = "APP") =>
    persist
      ? store.dispatch(PActions.setPScreenState(screenName, obj))
      : store.dispatch(UnpActions.setVScreenState(screenName, obj));

  const propsToPass = {
    utils: utils({
      ...data,
      projectData: data.projectData,
      setScreenState: data.setScreenState || setScreenState,
    }),
    api: api,
    databaseModule: databaseModule,
    NodeData,
  };

  const autoTrigger = new AutoTrigger(propsToPass);
  await autoTrigger.executeTrigger(trigger, data);
};

const processTriggers = async (data) => {
  for (let i = 0; i < data.records?.length; i++) {
    const record = data.records[i];

    const triggers = data.projectData.triggers.filter(
      (x) => x.triggerLocation === "local" && x.triggerOn === data.triggerOn
    );

    for (let i = 0; i < triggers.length; i++) {
      const trigger = triggers[i];

      await processTrigger(trigger, { ...data, record }).catch((e) =>
        console.error("Error in processTrigger: " + e.message)
      );
    }
  }
};

export default databaseModule;
