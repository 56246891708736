import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { store } from "../../store";
import api from "../../Services/Api/api";

export default function PaypalPay(props) {
  const [clientId, setClientId] = useState(null);

  const load = async () => {
    try {
      const activeProjectId = store?.getState()?.pState?.APP?.projectData?._id;

      const { publicKey } = await api
        .get("v1/payment/stripe/public-key", {
          filter: JSON.stringify({
            where: { project: activeProjectId, provider: "paypal" },
          }),
        })
        .then((x) => {
          console.log("res: ", x);
          return x;
        });

      setClientId(publicKey);
    } catch (e) {
      console.warn("Unable to fetch publishable key. Is server running?");
      window.alert("Error: Unable to initialise payment");
      return null;
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (!clientId || !props.price) return null;

  const buttonProps = props.paymentType === 'recurring' ? {
    createSubscription: (data, actions) => {
      console.log('recurring: ', {data, actions})
      return actions.subscription.create({
        plan_id: props.price,
      });
    }
  } : {
    createOrder: (data, actions) => {
      console.log({data, actions})
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: props.price,
            },
          },
        ],
      });
    }
  }

  return (
    <PayPalScriptProvider options={{ "client-id": clientId, vault: true }}>
      <PayPalButtons
        {...buttonProps}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            console.warn(`Transaction completed by ${name}`);
            props.callback(null, details);
          });
        }}
      />
    </PayPalScriptProvider>
  );
}
