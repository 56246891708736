import React from "react";

import RenderInputBase from "./RenderInputBase";

class RenderCheckbox extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  render() {
    const checked = this.toBoolean(this.value);
    return (
      <label style={this.styles.label}>
        <input
          {...{
            type: "checkbox",
            style: this.styles.input,
            checked: checked,
            onChange: (x) => {
              this.onChangeValue(!checked);
            },
          }}
        />
        {this.props.activeTab?.inputData?.labelText || ""}
      </label>
    );
  }
}

export default RenderCheckbox;
