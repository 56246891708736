// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment with the PaymentRequestButton using the official Stripe docs.
// https://stripe.com/docs/stripe-js/elements/payment-request-button#react

import React, { useState, useEffect } from "react";

import {
  PaymentRequestButtonElement,
  Elements,
  useStripe,
  CardElement,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import api from "../../Services/Api/api";
import "./stripe.css";

const Result = ({ children }) => <div className="result">{children}</div>;

const ErrorResult = ({ children }) => <div className="error">{children}</div>;

const NotAvailableResult = () => (
  <Result>
    <p style={{ textAlign: "center" }}>
      PaymentRequest is not available in your browser.
    </p>
    {window.location.protocol !== "https:" && (
      <p style={{ textAlign: "center" }}>
        Try using{" "}
        <a href="https://ngrok.com" target="_blank" rel="noopener noreferrer">
          ngrok
        </a>{" "}
        to view this demo over https.
      </p>
    )}
  </Result>
);

const ELEMENT_OPTIONS = {
  style: {
    paymentRequestButton: {
      type: "buy",
      theme: "dark",
    },
  },
};

const CheckoutFormOnetime = ({ dpmCheckerLink, callback }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href, // Optional, only needed for payment methods that require redirect (e.g. Apple Pay, GPay)
      },
      redirect: "if_required",
    });

    console.log({ error, paymentIntent });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
        callback?.(error);
      } else {
        setMessage(error.message || "Something went wrong");
        callback?.(error);
      }
    } else {
      callback?.(null, { paymentIntent });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              "Pay now"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      {/* [DEV]: Display dynamic payment methods annotation and integration checker */}
      {/* <div id="dpm-annotation">
        <p>
          Payment methods are dynamically displayed based on customer location,
          order amount, and currency.&nbsp;
          <a
            href={dpmCheckerLink}
            target="_blank"
            rel="noopener noreferrer"
            id="dpm-integration-checker"
          >
            Preview payment methods by transaction
          </a>
        </p>
      </div> */}
    </>
  );
};

const CheckoutFormOnetimeOld = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notAvailable, setNotAvailable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {
    if (!stripe || stripeLoaded) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }

    try {
      setStripeLoaded(true);

      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total Amount",
          amount: isNaN(props.price) ? props.price : parseFloat(props.price),
        },
      });

      pr.on("paymentmethod", async (event) => {
        setPaymentMethod(event.paymentMethod);
        event.complete("success");
        props.callback?.(null, { paymentMethod: event.paymentMethod });
      });

      pr.canMakePayment().then((canMakePaymentRes) => {
        if (canMakePaymentRes) {
          setPaymentRequest(pr);
        } else {
          setNotAvailable(true);
        }
      });
    } catch (e) {
      props.callback?.(e);
    }
  }, [stripe]);

  return (
    <form>
      {paymentRequest && (
        <PaymentRequestButtonElement
          onClick={(event) => {
            if (paymentMethod) {
              event.preventDefault();
              setErrorMessage(
                "You can only use the PaymentRequest button once. Refresh the page to start over."
              );
            }
          }}
          options={{
            ...ELEMENT_OPTIONS,
            paymentRequest,
          }}
        />
      )}
      {notAvailable && <NotAvailableResult />}
      {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
      {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
    </form>
  );
};

const CheckoutFormRecurring = (props) => {
  const [loading, setLoading] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe.js hasn't loaded yet.
    }

    setLoading("subscription");
    try {
      // Confirm the payment method using PaymentElement
      const confirmation = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href, // Optional, only needed for payment methods that require redirect (e.g. Apple Pay, GPay)
        },
        redirect: "if_required", // Disable auto redirect if not needed
      });

      let { error } = confirmation;

      props.callback?.(error, confirmation);
    } catch (error) {
      console.error(error);
      props.callback?.(error);
    }

    setLoading(false);

    // // Create Payment Method
    // const { paymentMethod, error } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // });

    // if (error) {
    //   console.error(error);
    // } else {
    //   setLoading("subscription");
    //   try {
    //     const response = await api.post(
    //       "v1/payment/stripe/create-subscription",
    //       {
    //         data: {
    //           paymentMethodId: paymentMethod.id,
    //           price: props.price,
    //           customerId: props.customerId,
    //         },
    //         project: props.projectData._id,
    //       }
    //     );

    //     const { subscription, clientSecret } = response.data;

    //     // Step 3: Confirm payment (if required, e.g., for 3D Secure)
    //     if (clientSecret) {
    //       const { error: confirmError } = await stripe.confirmPayment({
    //         payment_method: paymentMethod.id,
    //         client_secret: clientSecret,
    //       });

    //       if (confirmError) {
    //         console.error("Error confirming payment:", confirmError);
    //         props.callback?.(confirmError);
    //       } else {
    //         console.log("Subscription successful:", subscription);
    //         props.callback?.(null, subscription);
    //       }
    //     } else {
    //       console.log(
    //         "Subscription created successfully without confirmation:",
    //         subscription
    //       );
    //       props.callback?.(null, subscription);
    //     }
    //   } catch (error) {
    //     props.callback?.(error);
    //   }
    //   setLoading(null);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <CardElement /> */}
      <PaymentElement
        id="payment-element"
        options={{
          layout: "tabs",
        }}
      />
      <button type="submit" disabled={!!loading}>
        {loading ? "Subscribing" : "Subscribe"}
      </button>
    </form>
  );
};

export const StripePayCheckoutRecurring = (props) => {
  const appearance = {
    theme: "stripe",
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <Elements
      options={{ clientSecret: props.clientSecret, appearance, loader }}
      stripe={props.stripePromise}
    >
      <CheckoutFormRecurring {...props} />
    </Elements>
  );
};

export const StripePayCheckoutOneTime = (props) => {
  const appearance = {
    theme: "stripe",
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = "auto";

  return (
    <Elements
      options={{ clientSecret: props.clientSecret, appearance, loader }}
      stripe={props.stripePromise}
    >
      <CheckoutFormOnetime {...props} />
    </Elements>
  );
};
