import React from "react";

const VideoMedia = (props) => {
  const { domNode, activeTab, elementDataStore } = props;

  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const value = elementDataStore?.value;

  return value ? (
    <audio
      {...{
        style: { width: "100%", height: "100%", ...(activeTabStyle || {}) },
      }}
      controls
    >
      <source src={value} type="audio/mp3"></source>
      Your browser does not support the audio element.
    </audio>
  ) : null;
};

export default VideoMedia;
