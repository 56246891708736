import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  StripePayCheckoutRecurring,
  StripePayCheckoutOneTime,
} from "./StripePayCheckout";

import api from "../../Services/Api/api";
import { store } from "../../store";
import { deviceId } from "../../Providers/deviceId";

const fetchPublishableKey = async ({ projectId }) => {
  try {
    const { publicKey } = await api.get("v1/payment/stripe/public-key", {
      filter: JSON.stringify({
        where: { project: projectId, provider: "stripe" },
      }),
    });

    // return 'pk_test_51JiIrFSDy4FyoZAkUnehXYo2mkAfocvXKdlz5U6b3yYhixDkUydsSBjZ2MXOYi7gqY79LxPdkgzFbnGebsjsaino00TPo296my';
    return publicKey;
  } catch (e) {
    console.warn("Unable to fetch publishable key. Is server running?");
    window.alert("Error: Unable to initialise payment");
    return null;
  }
};

const fetchPaymentIntentClientSecret = async ({ amount, projectId }) => {
  const res = await api.post("v1/payment/stripe/create-payment-intent", {
    data: {
      currency: "usd",
      amount: (amount || 0) * 100,
      client: "web",
    },
    project: projectId,
  });

  return res;
};

const fetchSubscriptionClientSecret = async ({ projectId, price }) => {
  const response = await api.post("v1/payment/stripe/create-subscription", {
    data: {
      price: price,
      payment_behavior: "default_incomplete",
      payment_settings: { save_default_payment_method: "on_subscription" },
      customerDetails: {
        email: projectId + "_" + deviceId + "@appxolo.com",
        name: projectId + "_" + deviceId,
        address: {
          city: "XYZ",
          country: "US",
          line1: "xyz",
          // postal_code: "97712",
          // state: "CA",
        },
      },
    },
    project: projectId,
  });

  return response;
};

let stripePromise;

const StripePay = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const [publishableKey, setPublishableKey] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      setError(false);

      const activeProjectId = store?.getState()?.pState?.APP?.projectData?._id;

      const pubkeyP = fetchPublishableKey({
        projectId: activeProjectId,
      }).then((publishableKey) => {
        setPublishableKey(publishableKey);
        stripePromise = loadStripe(publishableKey);
      });

      if (props.paymentType === "recurring") {
        await fetchSubscriptionClientSecret({
          projectId: activeProjectId,
          price: props.price,
        }).then(({ subscription, clientSecret, customer }) => {
          setClientSecret(clientSecret);
          if (customer?.id) setCustomerId(customer.id);
          if (subscription) setSubscription(subscription);
        });
      } else {
        await fetchPaymentIntentClientSecret({
          projectId: activeProjectId,
          amount: props.price,
        }).then(({ clientSecret, customerId, dpmCheckerLink }) => {
          setClientSecret(clientSecret);
          setDpmCheckerLink(dpmCheckerLink);
          if (customerId) setCustomerId(customerId);
        });
      }

      await pubkeyP;

      setLoading(false);
    } catch (e) {
      setError("Error initialising payment: " + e.message);
      setLoading(false);
    }
  };

  if (loading || !stripePromise) return <div>Loading...</div>;
  else if (error) return <div>{error}</div>;
  else if (publishableKey && props.paymentType === "recurring")
    return (
      <StripePayCheckoutRecurring
        {...{
          ...props,
          publishableKey,
          stripePromise,
          clientSecret,
          customerId,
          subscription,
        }}
      />
    );
  else if (publishableKey && clientSecret)
    return (
      <StripePayCheckoutOneTime
        {...{
          ...props,
          publishableKey,
          clientSecret,
          dpmCheckerLink,
          stripePromise,
        }}
      />
    );
  else return <div>Loading Error</div>;
};

export default StripePay;
