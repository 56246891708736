import React from "react";

import RenderInputBase from "./RenderInputBase";
import Switch from "../../../../Components/etc/Switch/Switch";

class RenderSwitch extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  render() {
    const checked = this.toBoolean(this.value);
    return (
      <label
        style={Object.assign(
          {},
          { display: "flex", alignItems: "center" },
          this.styles.label
        )}
      >
        <Switch
          {...{
            // style: this.styles.input,
            checked: checked,
            onChange: (x) => {
              this.onChangeValue(!checked);
            },
          }}
        />
        {this.props.activeTab?.inputData?.labelText || ""}
      </label>
    );
  }
}

export default RenderSwitch;
