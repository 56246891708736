import React from "react";
import { useInView } from "react-intersection-observer";

function InViewPort(props) {
  const [ref, inView] = useInView({
    triggerOnce: !!props.triggerOnce,
  });

  if (inView) {
    props.callback();
  }

  return <div ref={ref}></div>;
}

export default InViewPort;
