import React from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import AppModal from "../../../../Components/Models/app-modal";

class RenderImage extends React.Component {
  state={galleryView: false};

  showImageGallery(data){
    this.setState({galleryView: data})
  }

  render() {
    const {
      props: { domNode, activeTab, elementDataStore },
      state: {galleryView}
    } = this;

    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
    const imageDisplayMode = activeTab?.imageDisplayMode;

    const valueArray = ["repeating", "gallery"].includes(imageDisplayMode)
      ? elementDataStore?.value || []
      : [elementDataStore?.value?.[0]];

    return (
      <>
        {valueArray.map((item, i) => (
          <img
            key={i + item?.value}
            style={activeTabStyle}
            src={item?.value?.toString()}
            alt="img"
            onClick={() => this.showImageGallery({ valueArray, i })}
          />
        ))}

        {galleryView && activeTab?.zoomboxOnPopup ? (
          <ImageFullScreenCarousel
            {...{ galleryView, close: ()=>this.setState({ galleryView: null }) }}
          />
        ) : null}
      </>
    );
  }
}

class ImageFullScreenCarousel extends React.Component {
  state = {valueArray: this.props.galleryView?.valueArray, i: this.props.galleryView?.i};

  render(){
    const {state: {valueArray, i}, props: {close}} = this;

   return (
     <AppModal
       {...{
         title: "",
         showFooter: false,
         showHeader: false,
         visible: true,
         handleClose: close,
         backdrop: "dynamic",
         modalProps: { size: "lg" },
       }}
     >
       <CarouselProvider
         naturalSlideWidth={window.screen.availWidth || window.screen.width}
         naturalSlideHeight={window.screen.availHeight || window.screen.height}
         totalSlides={valueArray.length}
         currentSlide={i}
       >
         <div style={{ width: "100%", height: "100%", position: "relative" }}>
          
           <Slider>
             {valueArray.map((item, i) => (
               <Slide key={i + item?.value}>
                 <img
                   style={{
                     width: "100%",
                     height: "100%",
                     objectFit: "contain",
                   }}
                   src={item?.value?.toString()}
                   alt="img"
                 />
               </Slide>
             ))}
           </Slider>
           <ButtonBack
             style={{ position: "absolute", top: 0, bottom: 0, left: 0 }}
             onClick={() => {this.setState({ i: i - 1 })}}
           >
             Back
           </ButtonBack>
           <ButtonNext
             style={{ position: "absolute", top: 0, bottom: 0, right: 0 }}
             onClick={() => {this.setState({ i: i + 1 })}}
           >
             Next
           </ButtonNext>
         </div>
       </CarouselProvider>
     </AppModal>
   );

  }

}

export default RenderImage;
