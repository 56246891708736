import React from "react";
import MapContainer from "./MapContainer";
class RenderMap extends React.Component {
  render() {
    const {
      props: { domNode, activeTab, elementDataStore },
    } = this;
    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];

    return (
      <>
        <MapContainer
          {...{
            key:
              elementDataStore?.operations?.map((x) => x.operation).join(",") ||
              "emptymap",
            data: elementDataStore,
            onPress: this.props.onPress,
            style: activeTabStyle,
          }}
        />
      </>
    );
  }
}

export default RenderMap;
