import React from "react";
import WebrtcMedia from "./WebrtcMedia";

const VideoMedia = (props) => {
  const { domNode, activeTab, elementDataStore } = props;

  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const value = elementDataStore?.value;

  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  return value ? (
    urlRegex.test(value) ? (
      <video
        {...{
          src: value,
          style: { width: "100%", height: "100%", ...(activeTabStyle || {}) },
          controls: true,
        }}
      />
    ) : (
      <WebrtcMedia {...props} />
    )
  ) : null;
};

export default VideoMedia;
