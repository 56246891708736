const utils = {
  getWeekDays: (locale = "en-US", option = {}) => {
    var baseDate = new Date(Date.UTC(2017, 0, 1)); // just a Monday
    var weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        baseDate.toLocaleDateString(locale, {
          weekday: option.format || "short",
        })
      );
      baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
  },

  getFirstDayOfWeek: (date) => {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const diff = dayOfWeek;
    currentDate.setDate(currentDate.getDate() - diff);

    return currentDate;
  },

  getFirstDayOfMonth: (date) => {
    const firstDayOfMonth = new Date(date);
    firstDayOfMonth.setDate(1);
    return firstDayOfMonth;
  },

  //   parseDate: (x) =>
  //     !x && x !== 0
  //       ? null
  //       : isNaN(x)
  //       ? new Date(x)
  //       : x.getTime
  //       ? new Date(x)
  //       : new Date(parseInt(x)),

  parseDate: (input) => {
    if (input === undefined || input === null) {
      return null;
    } else if (
      typeof input === "number" ||
      (typeof input === "string" && /^\d+$/.test(input))
    ) {
      return new Date(Number(input));
    } else if (input instanceof Date) {
      return new Date(input);
    } else if (typeof input === "string") {
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;
      if (timePattern.test(input)) {
        const parts = input.split(":");
        const hours = Number(parts[0]);
        const minutes = Number(parts[1]);
        const seconds = parts[2] ? Number(parts[2]) : 0;

        if (
          hours >= 0 &&
          hours <= 23 &&
          minutes >= 0 &&
          minutes <= 59 &&
          seconds >= 0 &&
          seconds <= 59
        ) {
          const currentDate = new Date();
          currentDate.setHours(hours, minutes, seconds, 0);
          return currentDate;
        }
      }
      const parsedDate = new Date(input);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    }

    return null;
  },

  toDateOnlyFormat: (d) => {
    const date = new Date(d);

    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if (isNaN(dd)) dd = 0;
    if (isNaN(MM)) MM = 0;
    if (isNaN(yyyy)) yyyy = "0000";

    dd = dd < 10 ? "0" + dd.toString() : dd.toString();
    MM = MM < 10 ? "0" + MM.toString() : MM.toString();
    yyyy = yyyy.toString();

    return yyyy + "-" + MM + "-" + dd;
  },

  formatDateToYYYYMMDDHHMM: (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  },

  formatDateToHHMM: (date) => {
    if (!(date instanceof Date)) return null;
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  },

  formatDateToHHMMSS: (date) => {
    if (!(date instanceof Date)) return null;
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  },

  compareLocalTimeTo: (dateInstance, timeValue) => {
    const localHours = dateInstance.getHours();
    const localMinutes = dateInstance.getMinutes();

    const [givenHours, givenMinutes] = timeValue.split(":").map(Number);

    if (
      localHours < givenHours ||
      (localHours === givenHours && localMinutes < givenMinutes)
    ) {
      return -1;
    } else if (
      localHours > givenHours ||
      (localHours === givenHours && localMinutes > givenMinutes)
    ) {
      return 1;
    } else {
      return 0;
    }
  },
};

export default utils;
