import React from "react";

import RenderInputBase from "./RenderInputBase";
import api from "../../../../Services/Api/api";

class RenderFileInput extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  inputEl = React.createRef(null);

  async selectFile(files) {
    try {
      console.log({ files });
      if (!files?.length) return;

      const result = await api.media(
        "v1/file",
        { file: files[0] },
        { onUploadProgress: (x) => console.info("on upload progress: ", x) }
      );

      const fullUrl = api.getFileLink(result.file, {
        params: { mime_type: result.file?.file_mime_type },
      });

      this.onChange({ value: fullUrl, valueObj: { ...result, uri: fullUrl } });
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    const style = {
      backgroundColor: "lightgray",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      ...(this.styles?.input || {}),
    };

    const label = this.toString(this.valueObj?.label?.value);

    return (
      <>
        <div style={style} onClick={() => this.inputEl.current.click()}>
          {this.renderLabel({}, label || "Upload")}
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          ref={this.inputEl}
          defaultValue={""}
          onChange={(e) => this.selectFile(this.selectFile(e.target.files))}
        />
      </>
    );
  }
}

export default RenderFileInput;
