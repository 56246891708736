import Bowser from "bowser";

import { deviceId } from "../../Providers/deviceId";
import api from "../../Services/Api/api";
import { requestForToken } from "../firebase/firebase";
import callModule from "../call/call-module";
import { store } from "../../store";
import { getRouter } from "../../Providers/router";
import { v4 as uuidv4 } from "uuid";
import { socketService } from "../../Services/Socket/socketListeners";

const browser = Bowser.getParser(window.navigator.userAgent);

let currentLocation = null;
let currentLocationWatcher = null;
const getSavedCurrentLocation = () => currentLocation;

const watchCurrentLocation = () => {
  if (currentLocationWatcher) {
    // navigator.geolocation.clearWatch(currentLocationWatcher);
    return;
  }

  function handlePosition(data) {
    if (data) currentLocation = data;
  }

  if (navigator?.geolocation?.watchPosition) {
    currentLocationWatcher =
      navigator.geolocation.watchPosition(handlePosition);
  }
};

const utils = (props) => ({
  getScreenWidth: async () => window.innerWidth,
  getScreenHeight: async () => window.innerHeight,
  getDeviceModal: async () => browser.getBrowserName(),
  getDeviceVersion: async () => browser.getBrowser().version,
  getPlatform: async () => ({ OS: "web" }),
  getDeviceId: async () => deviceId,
  getNotificationToken: async () => requestForToken(),
  navigate: async (screenId, params = {}) => {
    const screen = props.projectData.screens?.find((x) => x._id === screenId);
    if (screen) {
      const url =
        "/" + screen.data?.url + "?" + new URLSearchParams(params).toString();

      const navigate = props.router?.navigate || getRouter()?.navigate;
      if (navigate) navigate(url);
      else window.location = url;
    }
  },
  setScreenModal: async (screenId, params) => {
    const screen = props.projectData.screens?.find((x) => x._id === screenId);
    if (screen) {
      let screenParams = params || {};

      const passedParameters = [];
      for (const key in screenParams) {
        if (Object.hasOwnProperty.call(screenParams, key)) {
          const value = screenParams[key];
          passedParameters.push({
            uid: Math.random(),
            urlParamName: key,
            value: value,
            sourceType: "urlParam",
          });
        }
      }

      const modalProps = {
        passedParameters,
        key: screen._id,
        route: null,
        screenId: screen._id,
      };

      if (props.setScreenModal) props.setScreenModal(modalProps);
      else props.setScreenState({ screenModal: modalProps });
    }
  },
  openLink: async (link) => {
    window.open(link, "_blank");
  },
  showAlert: async ({ title, message, buttons }) => {
    window.alert(message);
  },
  setPaymentPopup: async (x) => {
    props.setScreenState({ paymentModal: x });
  },
  sendPushNotification: async (data) =>
    api.socket("v1/app/pushNotification/send", data),

  sendSMS: async (data) => api.socket("v1/app/sms/send", data),
  sendEmail: async (data) => api.socket("v1/app/email/send", data),
  getCurrentLocation: () => {
    watchCurrentLocation();

    const savedCurrentLocation = getSavedCurrentLocation();
    if (savedCurrentLocation) return savedCurrentLocation;

    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        return reject(
          new Error("Geolocation is not supported by this browser")
        );
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      };

      function success(pos) {
        resolve(pos);
      }

      function error(err) {
        reject(err);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    });
  },
  initiateWebrtcCall: async (data) => {
    return callModule.initiateCall(data).catch(console.error);
  },
  callModule,
  setMicrophoneStatus: async (opt = {}) => {
    const isMuted =
      opt.status === "unmute"
        ? false
        : opt.status === "mute"
        ? true
        : !store.getState()?.vState?.APP?.isMuted;

    props.setScreenState({
      isMuted,
    });

    return { isMuted };
  },
  setCameraStatus: async (opt = {}) => {
    const isCameraDisabled =
      opt.status === "on"
        ? false
        : opt.status === "off"
        ? true
        : !store.getState()?.vState?.APP?.isCameraDisabled;

    props.setScreenState({
      isCameraDisabled,
    });

    return { isCameraDisabled };
  },
  getState: async () => store.getState(),
  uuidv4,
  socketService,
});

export default utils;
