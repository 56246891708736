import dateTimeModule from "./datetime.js";

const operatorsBase = {
  multiply: {
    label: 'Multiplied By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 7,
    operate: (x, y) => x * y,
  },
  division: {
    label: 'Divided By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 7,
    operate: (x, y) => x / y,
  },
  plus: {
    label: 'Added To',
    inputTypes: ['string', 'number'],
    outputType: 'number',
    precedence: 6,
    operate: (x, y) => isNaN(x) || isNaN(y) ? x + y : parseFloat(x) + parseFloat(y),
  },
  minus: {
    label: 'Subtracted By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 6,
    operate: (x, y) => isNaN(x) || isNaN(y) ? x - y : parseFloat(x) - parseFloat(y),
  },
  concat: {
    label: 'Concat With',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => (x?.toString?.() || '') + (y?.toString?.() || ''),
  },
  addCommaItem: {
    label: 'Add Comma Item',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => (x?.toString?.() || '') + (x && y? (', '+y?.toString?.() || ''): y?.toString?.()||""),
  },
  removeCommaItem: {
    label: 'Remove Comma Item',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => {
      let list = x
        ?.toString()
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x !== y?.toString()?.trim());

        return list?.join(", ") || "";
    },
  },
  greaterOrEqual: {
    label: 'Is Greater Then Or Equal To',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x >= y,
  },
  lessOrEqual: {
    label: 'Is Less Than Or Equal To',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x <= y,
  },
  greaterThan: {
    label: 'Is Greater Than',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x > y,
  },
  lessThan: {
    label: 'Is Less Than',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x < y,
  },
  equal: {
    label: 'Equals',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 4,
    operate: (x, y) => x == y,
  },
  notEqual: {
    label: 'Does Not Equals',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 4,
    operate: (x, y) => x != y,
  },
  OR: {
    label: 'Or',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 3,
    operate: (x, y) => !!(x || y),
  },
  AND: {
    label: 'And',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 2,
    operate: (x, y) => !!(x && y),
  },
  IN: {
    label: 'IN',
    inputTypes: ['any'],
    outputType: 'null',
    precedence: 8,
    operate: (x, y, options) => 0, // @TODO
  },
};

let operators = {};

for (const operatorName in operatorsBase) {
  if (Object.prototype.hasOwnProperty.call(operatorsBase, operatorName)) {
    const operator = operatorsBase[operatorName] || {};

    const newOperate = (stackX, stackY, options) => {
      let valueX = stackX.value;
      let valueY = stackY.value;

      if (
        (isPureDateValue(stackX) || isPureDateValue(stackY)) &&
        isDateValue(stackX) &&
        isDateValue(stackY) &&
        [
          "multiply",
          "division",
          "plus",
          "minus",
          "greaterOrEqual",
          "lessOrEqual",
          "greaterThan",
          "lessThan",
          "equal",
          "notEqual",
        ].includes(operatorName)
      ) {
        valueX = getTimestampFromDateStack(stackX);
        valueY = getTimestampFromDateStack(stackY);

        let valueResult = operator.operate?.(valueX, valueY, options);

        return {
          value: isPureDateValue(stackX)
            ? dateTimeModule.formatDate(
                new Date(valueResult),
                stackX.valueData?.customFormat,
                stackX.valueData?.formatType
              )
            : isPureDateValue(stackY)
            ? dateTimeModule.formatDate(
                new Date(valueResult),
                stackY.valueData?.customFormat,
                stackY.valueData?.formatType
              )
            : valueResult,

          valueData: {
            date: dateTimeModule.parseDateFromString(valueResult),
            stringType: "date",
            formatType: isPureDateValue(stackX)
              ? stackX.valueData?.formatType
              : stackY.valueData?.formatType,
            customFormat: isPureDateValue(stackX)
              ? stackX.valueData.customFormat
              : stackY.valueData.customFormat,
          },
        };
      } else {
        return {
          value: operator.operate?.(valueX, valueY, options),
        };
      }
    };

    operators[operatorName] = {
      ...operator,
      operate: newOperate,
    };
  }
}

const getTimestampFromDateStack = (stack) => {
  const ms = new Date(stack?.valueData?.date).getTime();
  return isNaN(ms) ? dateTimeModule.timeToMilliseconds(stack?.value || "") : ms;
};

const isDateValue = (stack) => {
  if (
    isPureDateValue(stack) ||
    dateTimeModule.timeToMilliseconds(stack?.value || "") !== null
  ) {
    return true;
  } else {
    return false;
  }
}

const isPureDateValue = (stack) => {
  return stack.valueData?.stringType === "date" && stack.valueData.date;
}

const operatorList = Object.keys(operators).map(x => ({
  ...operators[x],
  value: x,
}));

export default {operators, operatorList};
