import ExecuteTrigger from "./ExecuteTrigger.js";

class AutoTrigger extends ExecuteTrigger {
  constructor(props) {
    super(props);

    const { triggers: triggersProp, triggerLocation } = props;
    const triggers = triggersProp?.filter(
      (x) =>
        x.triggerOn === "auto" &&
        x.triggerLocation === triggerLocation &&
        x.triggerInterval
    );
    this.triggers = triggers;
  }

  run() {
    let triggerTimers = [];

    for (let i = 0; i < this.triggers?.length; i++) {
      const trigger = this.triggers[i];

      const map = { sec: 1, min: 60, hour: 60 * 60, day: 60 * 60 * 24 };
      let interval = parseInt(trigger.triggerInterval);
      interval = isNaN(interval) || !interval || interval < 1 ? 1 : interval;
      let sec = interval * (map[trigger.triggerIntervalUnit] || 1);

      const intervalTimer = setInterval(() => {
        this.executeTrigger(trigger)
          .then((x) => console.log("trigger executed"))
          .catch((e) => console.error("Error executing trigger: ", e));
      }, sec * 1000);

      triggerTimers.push(intervalTimer);
    }

    const stop = () => {
      console.log("stopping triggers");
      for (let i = 0; i < triggerTimers.length; i++) {
        const timer = triggerTimers[i];
        clearInterval(timer);
      }
    };

    this.stop = stop;

    return stop;
  }
}

export default AutoTrigger;
