import _operators from "./operators.js";
const operators = _operators.operators;

function calculateWithPrecedence(inputArray, options) {
  const stack = [...inputArray];

  while (stack.length > 1) {
    // Find the highest precedence
    let highestPrecedence = -1;
    let highestIndex = -1;
    for (let i = 1; i < stack.length; i++) {
      const currentPrecedence = operators[stack[i].operator]?.precedence || -1;
      if (currentPrecedence > highestPrecedence) {
        highestPrecedence = currentPrecedence;
        highestIndex = i;
      }
    }

    const xIndex = highestIndex - 1;
    const stackX = stack[xIndex];

    const stackY  = stack[highestIndex];

    const { operate } = operators[stackY?.operator];

    const result = operate(stackX, stackY, options);

    // Replace elements with the resultant value and previous operator
    stack.splice(xIndex, 2, {
      ...result,
      value: result.value,
      operator: stack[xIndex].operator,
    });
  }

  return stack[0].value;
}

export default calculateWithPrecedence;

// const inputArray = [
//   {value: 5, operator: 'If'},
//   {value: 3, operator: 'multiply'},
//   {value: 2, operator: 'division'},
//   {value: 1, operator: 'AND'},
//   {value: 8, operator: 'OR'},
//   {value: 6, operator: 'multiply'},
// ];
// const result = calculateWithPrecedence(inputArray);

// console.log('Expected result: ', 5 * 3 - 2 * 6);
// console.log('Result:', result); // Output the final result after all operations
