import React from "react";

class RenderText extends React.Component {
  state = { hover: false };

  hoverProps = {
    onMouseEnter: () => this.setState({ hover: true }),
    onMouseLeave: () => this.setState({ hover: false }),
  };

  processStyle() {
    const {
      props: { activeTab, domNode, passedParameters },
    } = this;

    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
    const hoverStyle = {
      ...(activeTabStyle || {}),
      ...(activeTab?.styleData?.hover || {}),
    };

    let style = this.state.hover ? hoverStyle : activeTabStyle;

    if (
      passedParameters?.filter(
        (x) => x.sourceType === "containerTabs" && x.containerTabs?.active
      ).length
    ) {
      let activeContainerTabStyle =
        activeTab?.styleData?.activeContainerTab || {};
      style = {
        ...style,
        ...activeContainerTabStyle,
      };
    }

    return style;
  }

  render() {
    const {
      props: { elementDataStore },
    } = this;

    const value = elementDataStore?.value || "";

    const style = this.processStyle();

    return (
      <div style={style} {...this.hoverProps}>
        {value}
      </div>
    );
  }
}

export default RenderText;
