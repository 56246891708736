class Storage {
  constructor(key) {
    this.key = key || "__LOCAL__DATABASE__";
  }

  async set(x) {
    return localStorage.setItem(this.key, JSON.stringify(x));
  }

  async get() {
    return JSON.parse(localStorage.getItem(this.key));
  }
}

export default Storage;
