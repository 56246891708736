import React, { Fragment, useEffect } from "react";

class ContainerTabs extends React.Component {
  state = {};

  componentDidMount() {
    this.setDefaultActiveTab();
  }

  setDefaultActiveTab() {
    this.setActiveTab({
      value: "",
    });
  }

  async handlePress(props, index) {
    this.setActiveTab({
      value: await this.getContainerTabValue(props, index),
      activeContainerTab: {
        index: index,
        rowIndices: props.rowIndices,
        rowIds: props.rowIds,
        elementId: props.nodeData.id,
      },
    });
  }

  async onChildLoad(props, index) {
    const containerTabsConfig = this.props.containerTabsConfig;
    const defaultActiveTab = containerTabsConfig?.defaultActiveTab;

    if (defaultActiveTab && !this.defaultActiveTabIsSet) {
      if (isNaN(defaultActiveTab)) {
        const value = this.getContainerTabValue(props, index);
        if (defaultActiveTab == value) {
          this.handlePress(props, index);
          this.defaultActiveTabIsSet = true;
        }
      } else {
        const defaultIndex = parseInt(defaultActiveTab) - 1;
        if (index === defaultIndex) {
          this.handlePress(props, index);
          this.defaultActiveTabIsSet = true;
        }
      }
    }
  }

  getContainerTabValue(props, index) {
    let activeTabValueElementIds =
      this.props.containerTabsConfig?.activeTabValueElementIds
        ?.split?.(",")
        .map((x) => x.trim().replace(/^#/, ""))
        .filter((x) => !!x);

    let tabValueElementId,
      value = null;
    for (let i = 0; i < activeTabValueElementIds?.length; i++) {
      const elementId = activeTabValueElementIds[i];
      const childIndices = [
        0,
        ...(props.dom.findRecursiveIndices(elementId) || []),
      ];
      const isChild =
        elementId === props.domNode.id ||
        props.dom.isChild(props.indices, childIndices);

      if (isChild) {
        tabValueElementId = elementId;
        break;
      }
    }

    if (tabValueElementId) {
      const closestValueElement = props.dataStore.getClosestElement({
        targetElementId: tabValueElementId,
        getterIndices: props.indices,
        getterRowIndices: props.rowIndices,
      });
      value = closestValueElement?.value;
    }

    return value;
  }

  getElementDataStore() {
    return this.props.dataStore.data[this.props.domNode.id]?.[
      this.props.rowIds.join()
    ];
  }

  setActiveTab(data) {
    this.setState(data || {});
    const elementDataStore = this.getElementDataStore();
    this.props.dataStore.mergeData({
      elementId: this.props.domNode.id,
      rowIndices: this.props.rowIndices,
      rowIds: this.props.rowIds,
      obj: {
        ...elementDataStore,
        ...data,
        elementId: this.props.domNode.id,
        rowIds: this.props.rowIds,
        rowIndices: this.props.rowIndices,
        updatedAt: Date.now(),
      },
    });
  }

  getChildContainerTabsProps(props, activeContainerTab) {
    const active = !!(
      activeContainerTab &&
      activeContainerTab?.elementId === props.nodeData.id &&
      activeContainerTab?.rowIds?.join() === props?.rowIds?.join()
    );
    return {
      activeContainerTab,
      active,
      tabStatus: active ? "active" : "inactive",
    };
  }

  render() {
    const {
      props: { activeTabStyle, children, domNode, rowIndices, rowIds },
    } = this;

    // const activeContainerTab = this.getElementDataStore()?.activeContainerTab;
    const activeContainerTab = this.state.activeContainerTab;

    return (
      <div style={activeTabStyle}>
        {React.Children.map(children, (child, index) => {
          return {
            ...child,
            props: {
              ...child.props,

              intermediateProps: {
                mode: "conatainerTabsItem",
                index,

                render: (props) => {
                  const passedParameter = {
                    sourceType: "containerTabs",
                    elementId: domNode.id,
                    rowIndices: rowIndices,
                    rowIds,
                    containerTabs: this.getChildContainerTabsProps(
                      props,
                      activeContainerTab
                    ),
                  };

                  const passedParameters = [
                    ...props.passedParameters,
                    passedParameter,
                  ];

                  props.setExtraStates({ passedParameters });

                  return (
                    <Fragment
                      {...{
                        key: props.rowIds.join(),
                      }}
                    >
                      {props.renderChildren({
                        passedParameters,
                        triggerPress: (...x) => {
                          this.handlePress(props, index);
                        },
                      })}
                      <TriggerOnLoad
                        onLoad={() => this.onChildLoad(props, index)}
                      ></TriggerOnLoad>
                    </Fragment>
                  );
                },
              },
            },
          };
        })}
      </div>
    );
  }
}

const TriggerOnLoad = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onLoad();
    }, 0);

    setTimeout(() => {
      props.onLoad();
    }, 1000);

    setTimeout(() => {
      props.onLoad();
    }, 2500);

    setTimeout(() => {
      props.onLoad();
    }, 5000);
  }, []);
  return null;
};

export default ContainerTabs;
