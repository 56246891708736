import { v4 as uuidV4 } from "uuid";

const getDeviceId = () => {
  const savedDeviceId = localStorage.getItem("deviceId");
  if (!savedDeviceId) {
    let deviceId = uuidV4();
    localStorage.setItem("deviceId", deviceId);
    return deviceId;
  } else {
    return savedDeviceId;
  }
};

export const deviceId = getDeviceId();
