import React from "react";
import ReactDOM from "react-dom";
import { GoogleApiWrapper } from "google-maps-react";

import config from "../../../../Config";

const mapStyles = {
  map: {
    position: "unset",
    width: "100%",
    height: "100%",
  },
};

const isEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);

    const { lat, lng } = this.props.initialCenter || {};
    this.state = {
      currentLocation: {
        lat: lat,
        lng: lng,
      },
      locationMarkers: [],
      directionsService:
        this.props.google && new this.props.google.maps.DirectionsService(),

      locationWatcherId: undefined,
      mapCenter: null,
      zoom: null,
    };
  }
  componentDidMount() {
    this.loadMap();
    this.loadMarkersIfChanged();
    this.loadRouteIfChanged();
    this.panToMapCenterIfChanged();
    this.zoomMapIfChanged();
    // this.watchCurrentLocation();
  }
  componentWillUnmount() {
    if (this.state.locationWatcherId !== undefined) {
      console.log("clearing location watcher ", this.state.locationWatcherId);
      navigator.geolocation.clearWatch(this.state.locationWatcherId);
    }
  }

  componentDidUpdate() {
    this.loadMarkersIfChanged();
    this.loadRouteIfChanged();
    this.panToMapCenterIfChanged();
    this.zoomMapIfChanged();
  }

  watchCurrentLocation() {
    let that = this;
    function handlePosition(data) {
      let coordinates =
        data &&
        data.coords &&
        typeof data.coords.latitude === "number" &&
        typeof data.coords.longitude === "number"
          ? data.coords
          : false;

      if (coordinates)
        that.setState({
          currentLocation: {
            lat: coordinates.latitude,
            lng: coordinates.longitude,
          },
        });
    }
    let id =
      navigator &&
      navigator.geolocation &&
      navigator.geolocation.watchPosition(handlePosition);
    this.setState({ locationWatcherId: id });
  }

  loadMap() {
    if (this.props && this.props.google) {
      // checks if google is available
      console.log("Loading map: ", this.props);
      const { google } = this.props;
      const maps = google.maps;

      const mapRef = this.refs.map;

      // reference to the actual DOM element
      const node = ReactDOM.findDOMNode(mapRef);

      const mapData = this.props.data || {};

      let mapCenter = this.getLatLongOfLocationMark({
        value: mapData.mapCenter,
      });
      let zoom =
        mapData.zoom?.value && !isNaN(mapData.zoom?.value)
          ? parseInt(mapData.zoom.value || this.props.zoom)
          : this.props.zoom;
      this.setState({ mapCenter, zoom });

      const { lat, lng } =
        mapCenter.lat && mapCenter.lng ? mapCenter : this.state.currentLocation;
      const center = new maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
        }
      );
      // maps.Map() is constructor that instantiates the map
      this.map = new maps.Map(node, mapConfig);
    }
  }

  clearMarkers = (markers) => {
    if (!markers) return;
    for (let i = 0; i < markers.length; ++i) {
      markers[i]?.setMap(null);
    }
  };

  getLatLongOfLocationMark(locationMark) {
    return locationMark?.value?.geometry?.location || {};
  }

  loadMarkers(_markers = []) {
    const map = this.map;
    const google = this.props.google;

    if (!_markers.length || !map) return;

    if (this.state.locationMarkers) {
      this.clearMarkers(this.state.locationMarkers);
    }

    const markers = _markers.map((item) => {
      let x = {
        ...(item || {}),
        ...this.getLatLongOfLocationMark(item),
      };

      if (
        (!item && typeof item !== "object") ||
        x.lat === undefined ||
        x.lng === undefined
      )
        return;

      let image = null;
      let shape = null;

      if (x.icon && x.icon.src) {
        let width =
          x.icon.width && !isNaN(x.icon.width) ? parseInt(x.icon.width) : 36;
        let height =
          x.icon.height && !isNaN(x.icon.height) ? parseInt(x.icon.height) : 36;

        image = {
          url: x.icon.src,
          scaledSize: new google.maps.Size(width, height),
          // Uncomment the anchor if needed
          // anchor: new google.maps.Point(width / 2, height / 2)
        };

        shape = {
          coords: [1, 1, 1, width, width, height, height, 1],
          type: "poly",
        };
      }

      let marker = new google.maps.Marker({
        position: { lat: x.lat, lng: x.lng },
        map,
        title: x.title,
        icon: image,
        shape: shape,
      });

      marker.addListener("click", () =>
        this.props.onPress({ marker, locationMark: x })
      );

      return marker;
    });

    this.setState({ locationMarkers: markers, _markers });
  }

  loadMarkersIfChanged() {
    const mapData = this.props.data || {};
    const operations = mapData.operations || [];

    const markerOperations = operations.filter((x) => x?.operation === "mark");
    let _markers = [];

    for (let i = 0; i < markerOperations.length; i++) {
      const operation = markerOperations[i];

      _markers = [
        ..._markers,
        ...(operation?.locationMarks?.map((x) => ({
          ...x,
          ...this.getLatLongOfLocationMark(x),
        })) || []),
      ];
    }

    if (isEqual(this.state._markers, _markers)) return;

    this.loadMarkers(_markers);

    if (_markers.length) this.fitBounds(_markers);
  }

  zoomMapIfChanged() {
    const mapData = this.props.data || {};
    const zoom = mapData.zoom;

    if (!isNaN(zoom) && parseInt(zoom)) {
      this.zoomMap(parseInt(zoom));
    }
  }

  panToMapCenterIfChanged() {
    const mapData = this.props.data || {};

    let mapCenter = this.getLatLongOfLocationMark({
      value: mapData.mapCenter,
    });

    if (isEqual(this.state.mapCenter, mapCenter)) return;
    this.setState({ mapCenter });
    this.recenter(mapCenter);
  }

  zoomMap = (zoom) => {
    console.log("zooming to ", zoom);
    if (this.map && typeof zoom === "number") this.map.setZoom(zoom);
  };

  recenter = (center) => {
    if (
      this.map &&
      center &&
      typeof center === "object" &&
      typeof center.lat === "number" &&
      typeof center.lng === "number"
    ) {
      console.log("recentering.. ", center);
      this.map.panTo(center);
    } else {
      console.log("Invalid data to recenter..", { center, map: this.map });
    }
  };

  recenterIfSet = () => {
    const { mapCenter, zoom } = this.state;
    if (mapCenter?.lat || mapCenter?.lng) this.recenter(mapCenter);
  };

  fitBounds = (coordinates) => {
    const map = this.map;

    const bounds = new this.props.google.maps.LatLngBounds();

    coordinates.forEach((coordinate) => {
      bounds.extend(coordinate);
    });

    map.fitBounds(bounds);
  };

  loadRouteIfChanged = (options) => {
    const mapData = this.props.data || {};
    const operations = mapData.operations || [];

    const routeOperations = operations.filter((x) => x?.operation === "route");

    if (!routeOperations.length) return;

    const recenterIfSet = this.recenterIfSet.bind(this);

    const map = this.map;
    const google = this.props.google;

    let directionsService = this.state.directionsService || false;
    if (!directionsService) {
      directionsService = google && new google.maps.DirectionsService();
    }

    let routes = [];
    let isThereNewRoute = false;

    for (let i = 0; i < routeOperations.length; i++) {
      const operation = routeOperations[i];
      const { locationMarks, waypoints, polylineStrokeColor } = operation;

      let dispOption = {
        markerOptions: {
          visible: false, //!!options?.showLocationBubbles,
        },
        polylineOptions: {
          strokeColor: polylineStrokeColor,
        },
        preserveViewport: true,
      };

      let directionsDisplay = new google.maps.DirectionsRenderer();

      directionsDisplay.setMap(map);
      directionsDisplay.setOptions(dispOption);

      let origin = this.getLatLongOfLocationMark(locationMarks?.[0]);
      let destination = this.getLatLongOfLocationMark(locationMarks?.[1]);

      routes.push({ origin, destination });

      if (
        !this.state.routes?.find(
          (x) =>
            isEqual(x.origin, origin) && isEqual(x.destination, destination)
        )
      ) {
        isThereNewRoute = true;
        let travelMode = "DRIVING";

        let request = {
          origin,
          destination,
          travelMode,
          waypoints,
          optimizeWaypoints: false,
        };

        this.fitBounds([origin, destination]);

        const loadMarkers = this.loadMarkers.bind(this);
        directionsService.route(request, function (result, status) {
          console.log("routes result..", { status, routes: result });
          if (status == "OK") {
            directionsDisplay.setDirections(result);
            setTimeout(() => {
              recenterIfSet();
            }, 500);

            loadMarkers(
              [locationMarks[0], locationMarks[1]].filter((x) => x?.icon?.src)
            );
          }
        });
      }
    }

    if (isThereNewRoute) this.setState({ routes });
  };

  renderChildren() {
    const { children } = this.props;

    if (!children) return;

    return React.Children.map(children, (c) => {
      if (!c) return;
      return React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
        mapCenter: this.state.currentLocation,
      });
    });
  }

  render() {
    const style = Object.assign({}, mapStyles.map, this.props.style);

    return (
      <div>
        <div style={style} ref="map">
          Loading map...
        </div>
        {/* {JSON.stringify(this.state.currentLocation)} */}
        {this.renderChildren()}
      </div>
    );
  }
}

MapContainer.defaultProps = {
  zoom: 9,
  initialCenter: {
    lat: 40.7128,
    lng: -74.006,
  },
  centerAroundCurrentLocation: false,
  visible: true,
};

export default GoogleApiWrapper({
  apiKey: config.google.api_key,
})(MapContainer);
