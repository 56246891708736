import React from "react";
import _ from "lodash";

import NodeData from "./NodeData.js";

class RenderNode extends React.Component {
  constructor(props) {
    super(props);

    this.nodeData = new NodeData();

    this.dependentElements = []; // [{id: string, rowIds: [number]}];
    this.dependentDbTables = []; // [{dbId: string, tableName: string}];
    this.dependentUrlParams = [];
    this.subscriberId = `${props.domNode.id}-${this.nodeData.id}-renderNodeData`;
    this.periodicReloadMs = null;
  }

  componentWillUnmount() {
    this.unsubscribeSubscriptions();
  }

  getDependentDataStores() {
    this.props.dataStore.unsubscribeToAllElementChanges(this.subscriberId);

    const dataStores = this.dependentElements
      .map((x) => x.elementDataStore)
      .filter(
        (item, index, self) =>
          item?.id && index === self.findIndex((t) => t.id === item.id)
      );

    const allDataStores = [this.props.dataStore, ...dataStores];
    return allDataStores;
  }

  unsubscribeSubscriptions() {
    const allDataStores = this.getDependentDataStores();
    for (let i = 0; i < allDataStores.length; i++) {
      const dataStore = allDataStores[i];
      dataStore.unsubscribeToAllElementChanges(this.subscriberId);
    }
  }

  setDependentElements(element, { loadId }) {
    // element: {id: string, rowIds: array, ...rest}
    if (this.loadId !== loadId || element.id === this.props.domNode.id) return;
    else if (["urlParam"].includes(element.id)) {
      if (
        !this.dependentUrlParams.find(
          (x) => x.urlParamName === element.urlParamName
        )
      )
        this.dependentUrlParams.push(element);
    } else if (["CURRENT_TIME"].includes(element.id)) {
      this.periodicReloadMs = 1000;
    } else if (["CURRENT_LOCATION"].includes(element.id)) {
      this.periodicReloadMs = this.periodicReloadMs
        ? this.periodicReloadMs
        : 3000;
    } else if (
      !this.dependentElements.find(
        (x) =>
          x.id === element.id &&
          x.elementDataStore?.id == element.elementDataStore?.id
      )
    ) {
      this.dependentElements = [
        ...this.dependentElements,
        {
          ...element,
          elementDataStore: element.elementDataStore || this.props.dataStore,
        },
      ];
    }
  }

  setDependentDbTable({ dbId, tableId }, { loadId }) {
    if (this.loadId !== loadId) return;

    if (
      !this.dependentDbTables.find(
        (x) => x.dbId === dbId && x.tableId === tableId
      )
    ) {
      this.dependentDbTables = [...this.dependentDbTables, { dbId, tableId }];
    }
  }

  async loadNodeData() {
    const loadId = this.loadId;

    return this.nodeData.load({
      ...this.props,
      setDependentElements: (x) => this.setDependentElements(x, { loadId }),
      setDependentDbTable: (x) => this.setDependentDbTable(x, { loadId }),
    });
  }

  async getActiveTab() {
    const { tab } = await this.nodeData.getConditionSatisfiedData(
      this.props.domNode.value.data,
      this.extraStates
    );

    if (!tab) return null;

    // activeTab?.styleData?.[domNode?.value?.elementType]?.[
    //   Platform.OS.toLocaleLowerCase()
    // ];

    const activeTabStyleData = tab?.styleData || {};

    return {
      ...tab,
      styleData: await this.processConditionalStyles(activeTabStyleData),
    };
  }

  subscribeELementChanges(fn) {
    const allDataStores = this.getDependentDataStores();

    for (let i = 0; i < allDataStores.length; i++) {
      const dataStore = allDataStores[i];
      dataStore.unsubscribeToAllElementChanges(this.subscriberId);

      let elements = this.dependentElements.filter(
        (x) => x.elementDataStore?.id === dataStore.id
      );

      if (elements.length) {
        dataStore.subscribeToElemntChanges(elements, {
          elementId: this.props.domNode.id,
          nodeId: this.nodeData.id,
          subscriberId: this.subscriberId,
          fn,
        });
      }
    }
  }

  async computeStyles(data, environment) {
    const { default: defaultStyles = {}, conditions = [] } = data;
    const { platform, screenWidth, screenHeight, hover } = environment;

    let finalStyles = JSON.parse(JSON.stringify(defaultStyles[platform] || {}));

    conditions.forEach(({ condition = {}, styles = {} }) => {
      const {
        heightOrWidth,
        operator,
        value,
        hover: conditionHover,
      } = condition;

      const matchesHover = conditionHover === hover;
      const matchesPlatform = styles[platform] !== undefined;
      const matchesDimension = (() => {
        const envValue =
          heightOrWidth === "screenWidth" ? screenWidth : screenHeight;
        const conditionValue = parseInt(value, 10);

        if (operator === "lessThan") return envValue < conditionValue;
        if (operator === "greaterThan") return envValue > conditionValue;
        return false;
      })();

      if (matchesHover && matchesDimension && matchesPlatform) {
        finalStyles = { ...finalStyles, ...styles[platform] };
      }
    });

    return finalStyles;
  }

  async processConditionalStyles(activeTabStyleData = {}) {
    const screenWidth = await this.props.utils.getScreenWidth();
    const screenHeight = await this.props.utils.getScreenHeight();

    const defaultStyles = await this.computeStyles(activeTabStyleData, {
      screenWidth,
      screenHeight,
      hover: false,
      platform: (await this.props.utils.getPlatform()).OS.toLocaleLowerCase(),
    });

    const hoverStyles = await this.computeStyles(
      { ...activeTabStyleData, default: activeTabStyleData.hover },
      {
        screenWidth,
        screenHeight,
        hover: true,
        platform: (await this.props.utils.getPlatform()).OS.toLocaleLowerCase(),
      }
    );

    const activeContainerTabStyle = await this.computeStyles(
      { default: activeTabStyleData.activeContainerTab },
      {
        screenWidth,
        screenHeight,
        hover: false,
        platform: (await this.props.utils.getPlatform()).OS.toLocaleLowerCase(),
      }
    );

    const styleData = {
      [this.props.domNode?.value?.elementType]: defaultStyles,
      hover: hoverStyles,
      activeContainerTab: activeContainerTabStyle,
    };

    return styleData;
  }

  toStringSync(value) {
    return [null, undefined].includes(value)
      ? ""
      : typeof value === "object" && value instanceof Array
      ? value.map((x) => x?.value).join()
      : value?.toString();
  }

  toValueArray(value) {
    switch (typeof value) {
      case "object":
        return value instanceof Array
          ? value.map((x) => (typeof x === "object" ? x : { value: x }))
          : [value];

      case "string":
        return value?.split(",").map((x) => ({ value: x.trim() }));
      default:
        return [value];
    }
  }

  parseDate(x) {
    return !x && x !== 0
      ? null
      : isNaN(x)
      ? new Date(x)
      : new Date(parseInt(x));
  }

  loadElementData = {
    container: async (options) => {
      const containerData = this.state.activeTab?.containerData;
      if (["slider"].includes(this.state.activeTab?.containerType?.type)) {
        let sliderConfig =
          this.state.activeTab?.containerType?.sliderConfig || {};
        await Promise.all(
          ["autoPlayInterval", "scrollCount"].map(async (key) => {
            if (sliderConfig[key]) {
              let value = await this.nodeData
                .evaluateQuickSelectionValue(sliderConfig[key])
                .catch((e) => console.warn(e));
              sliderConfig[key] = value?.value;
            }
          })
        );
        sliderConfig.loadCompleted = Date.now();
      } else if (
        ["containerTabs"].includes(this.state.activeTab?.containerType?.type)
      ) {
        let containerTabsConfig =
          this.state.activeTab?.containerType?.containerTabsConfig || {};
        await Promise.all(
          ["defaultActiveTab"].map(async (key) => {
            if (containerTabsConfig[key]) {
              let value = await this.nodeData
                .evaluateQuickSelectionValue(containerTabsConfig[key])
                .catch((e) => console.warn(e));
              containerTabsConfig[key] = value?.value;
            }
          })
        );
        containerTabsConfig.loadCompleted = Date.now();
      } else if (
        ["screen"].includes(this.state.activeTab?.containerType?.type)
      ) {
        let screenConfig =
          this.state.activeTab?.containerType?.screenConfig || {};
        if (screenConfig.urlParameters?.length) {
          await Promise.all(
            screenConfig.urlParameters?.map(async (urlParam) => {
              ["parameterName", "parameterValue"].map(async (key) => {
                if (urlParam[key]) {
                  let value = await this.nodeData
                    .evaluateQuickSelectionValue(urlParam[key])
                    .catch((e) => console.warn(e));
                  urlParam[key]["value"] = value?.value;
                }
              });
            })
          );
        }
        screenConfig.loadCompleted = Date.now();
      }

      const repeatingData = containerData?.repeating
        ? await this.repeatingData(containerData, options)
        : { value: [{ _id: "DEFAULT" }] };

      const repeatingContainers = repeatingData?.value?.map((row, i) => ({
        uid: row._id || row.id || i,
        row,
      }));

      return {
        ...repeatingData,
        value: JSON.stringify(repeatingData.value),
        repeatingContainers,
      };
    },
    text: async () => {
      const textData = this.state.activeTab?.textData;
      const textValue = (
        await Promise.all(
          (textData?.textParts || []).map((part) => this.evalTextPart(part))
        )
      )
        .join("")
        .trim();

      return { valueType: "string", value: textValue };
    },
    image: async () => {
      const data = this.state.activeTab?.imageData || {};
      const value = await this.nodeData
        .evaluateQuickSelectionValue({
          ...data,
          valueType: data.valueType || "staticUrls",
        })
        .catch(console.warn);

      return {
        ...(value || {}),
        valueType: "valueArray",
        value: this.toValueArray(value?.value).filter((x) => !!x),
      };
    },
    input: async () => {
      let result = {};
      const data = this.state.activeTab?.inputData || {};
      const value = await this.nodeData
        .evaluateQuickSelectionValue({ ...(data || {}) })
        .catch(console.warn);

      result = { ...(value || {}) };

      if (
        ["selectBox", "radio"].includes(this.state.activeTab?.inputType?.group)
      ) {
        await Promise.all(
          ["selected", "options"].map(async (key) => {
            if (data[key]) {
              let value = await this.nodeData
                .evaluateQuickSelectionValue(data[key])
                .catch((e) => console.warn(e));

              if (["options"].includes(key) && value?.value)
                value.value = this.toValueArray(value.value);
              result[key] = value;
            }
          })
        );
      }

      if (
        ["imagePicker", "filePicker"].includes(
          this.state.activeTab?.inputType?.group
        )
      ) {
        await Promise.all(
          ["label"].map(async (key) => {
            if (data[key]) {
              let value = await this.nodeData
                .evaluateQuickSelectionValue(data[key])
                .catch((e) => console.warn(e));

              result[key] = value;
            }
          })
        );
      }

      if (["dateTimePicker"].includes(this.state.activeTab?.inputType?.group)) {
        const datetimePicker = { ...(data.datetimePicker || {}) };

        await Promise.all(
          ["minDate", "maxDate"].map(async (key) => {
            const value = await this.nodeData
              .evaluateQuickSelectionValue(datetimePicker[key] || {})
              .catch(console.warn);
            if (value) datetimePicker[key] = new Date(value?.value).getTime();
          })
        );

        await Promise.all(
          ["minTime", "maxTime"].map(async (key) => {
            const value = await this.nodeData
              .evaluateQuickSelectionValue(datetimePicker[key] || {})
              .catch(console.warn);
            if (value) datetimePicker[key] = value?.value;
          })
        );

        await Promise.all(
          ["disabledDates", "allowedDates"].map(async (key) => {
            let value = await this.nodeData
              .evaluateQuickSelectionValue(datetimePicker[key] || {})
              .catch(console.warn);

            let values = this.toValueArray(value.value)
              .filter((x) => !!x)
              .map((x) => x.value);

            // if (value?.value && value.value instanceof Array) {
            //   for (let i = 0; i < value.value.length; i++) {
            //     const dateStr = value.value[i]?.value;

            //     if (typeof dateStr === "string") {
            //       const dateList = dateStr
            //         .split(",")
            //         .map((x) => x.trim())
            //         .filter((x) => !!x);
            //       values = [...values, ...dateList];
            //     } else if (dateStr) {
            //       values = [...values, dateStr];
            //     }
            //   }
            // }

            datetimePicker[key] = values
              .map((x) => this.parseDate(x)?.setHours(0, 0, 0, 0))
              .filter((x) => !isNaN(x));
          })
        );

        data.evaluatedDatetimePicker = datetimePicker;
      }

      return result;
    },
    media: async () => {
      const data = this.state.activeTab?.mediaData || {};
      const mediaType = data.mediaType || "video";
      const mediaTypeData = data[mediaType] || {};

      const value = await this.nodeData
        .evaluateQuickSelectionValue({
          ...mediaTypeData,
          valueType: mediaTypeData.valueType || "staticUrl",
        })
        .catch(console.warn);

      let result = { ...(value || {}) };

      if (["chart"].includes(mediaType)) {
        result = {
          ...(await this.nestedEvaluateQuickSelectionValue(mediaTypeData)),
          ...result,
        };

        result = this.nestedToArray(result, { parseFloat });
      }

      if (["webrtc"].includes(mediaType)) {
        await Promise.all(
          ["participantId", "mode"].map(async (key) => {
            if (mediaTypeData[key]) {
              result[key] = await this.nodeData
                .evaluateQuickSelectionValue({
                  valueType: "staticList",
                  ...(mediaTypeData[key] || {}),
                })
                .catch((e) => console.warn(e));
            }
          })
        );
      }

      return result;
    },
    map: async (options) => {
      const { periodicReload = false } = options;

      const elementDataStore =
        this.props.dataStore.data[this.props.domNode.id]?.[
          this.props.rowIds.join()
        ];
      const prevOperationsData = elementDataStore?.operations;

      const operations = this.state.activeTab?.mapData?.operations?.length
        ? await Promise.all(
            (this.state.activeTab?.mapData?.operations).map(
              async (operation, i) => {
                const operationName = operation.operation;

                if (
                  periodicReload &&
                  ["route"].includes(operationName) &&
                  prevOperationsData
                ) {
                  const prevOperation = prevOperationsData?.[i];

                  if (operation.uid === prevOperation?.uid) {
                    return prevOperation;
                  }
                }

                let result = {
                  ..._.omit(operation, [
                    "mapCenter",
                    "locationMarks",
                    "tableObj",
                  ]),
                };

                let mapCenter = await this.nodeData.evaluateQuickSelectionValue(
                  {
                    valueType: "textParts",
                    ...(operation?.mapCenter || {}),
                  }
                );
                let mapCenterLocation = await this.nodeData.valueTypes
                  .addressStrToLocation(mapCenter?.value)
                  .catch((e) =>
                    console.error("Error in mapcenter.addressStrToLocation", e)
                  );
                result.mapCenter = mapCenterLocation;

                if (operation.typeOfData === "repeating") {
                  let iconConfig = operation.locationMarks?.[0]?.icon;
                  if (iconConfig && iconConfig.valueType !== "database") {
                    let iconData =
                      await this.nodeData.evaluateQuickSelectionValue({
                        valueType: "staticUrls",
                        ...iconConfig,
                      });

                    iconConfig.value = this.toValueArray(iconData.value);
                    iconConfig = {
                      ...iconConfig,
                      ...iconData,
                      src: this.toValueArray(iconData?.value)?.[0]?.value,
                    };
                  }

                  result.radius =
                    await this.nodeData.evaluateQuickSelectionValue({
                      valueType: "textParts",
                      ...(operation.locationMarks?.[0]?.radius || {}),
                    });

                  result.plotCenter =
                    await this.nodeData.evaluateQuickSelectionValue({
                      valueType: "textParts",
                      ...(operation.locationMarks?.[0]?.plotCenter || {}),
                    });

                  let dbData = operation.locationMarks?.[0]?.tableObj;
                  if (!["mark"].includes(operationName)) {
                    dbData = { ...dbData, limit: 2 };
                  }

                  const dbResults = await this.nodeData.evalValue({
                    valueType: "tableSelector-valuesOfColumn",
                    valueObj: { dbData },
                  });

                  const locationMarks = await Promise.all(
                    dbResults?.value?.map(async (locationMark) => {
                      let addressStr = locationMark.value;

                      let location = await this.nodeData.valueTypes
                        .addressStrToLocation(addressStr)
                        .catch((e) =>
                          console.error(
                            "Error in locationMarks.addressStrToLocation",
                            e
                          )
                        );

                      let icon;
                      if (iconConfig.valueType === "database") {
                        const row = locationMark?.row;
                        const iconColumn = dbData?.columns?.[1];
                        icon = {
                          ...iconColumn,
                          src: row?.[iconColumn],
                        };
                      } else if (iconConfig.src) {
                        icon = iconConfig;
                      }

                      return {
                        ...locationMark,
                        icon,
                        iconConfig,
                        origin: addressStr,
                        value: location,
                        linkings: operation.locationMarks?.[0].linkings,
                      };
                    })
                  );

                  result.locationMarks = locationMarks.filter(
                    (x) => !!x?.value
                  );
                } else {
                  const locationMarks = operation.locationMarks?.length
                    ? await Promise.all(
                        operation.locationMarks?.map(async (locationMark) => {
                          let origin =
                            locationMark?.origin &&
                            (await this.nodeData.evaluateQuickSelectionValue({
                              valueType: "textParts",
                              ...(locationMark?.origin || {}),
                            }));

                          let location = await this.nodeData.valueTypes
                            .addressStrToLocation(origin?.value)
                            .catch((e) =>
                              console.error(
                                "Error in locationMarks.addressStrToLocation",
                                e
                              )
                            );

                          let icon = locationMark?.icon;
                          if (icon) {
                            let iconData =
                              await this.nodeData.evaluateQuickSelectionValue({
                                valueType: "staticUrls",
                                ...(locationMark?.icon || {}),
                              });
                            if (iconData) {
                              iconData.value = this.toValueArray(
                                iconData.value
                              );
                              icon = {
                                ...icon,
                                ...iconData,
                                src: iconData?.value?.[0]?.value,
                              };
                            }
                          }

                          return {
                            ...locationMark,
                            icon,
                            origin,
                            value: location,
                          };
                        })
                      )
                    : [];

                  result.locationMarks = locationMarks.filter(
                    (x) => !!x?.value
                  );
                }

                return result;
              }
            )
          )
        : null;

      let mapCenter = await this.nodeData
        .evaluateQuickSelectionValue({
          valueType: "textParts",
          ...(this.state.activeTab?.mapData?.mapCenter || {}),
        })
        .then((result) =>
          this.nodeData.valueTypes.addressStrToLocation(result?.value)
        )
        .catch((e) =>
          console.error("Error in mapcenter.addressStrToLocation", e)
        );

      return { operations, mapCenter };
    },
  };

  async repeatingData(containerData, options = {}) {
    const repeatingContainers =
      this.props.dataStore.data[this.props.domNode.id]?.[
        this.props.rowIds.join()
      ]?.repeatingContainers;

    const rowsResult = await this.nodeData.loadRepeatingContainerRow(
      containerData,
      {
        ...this.props,
        ...options,
        items: options.reload ? null : repeatingContainers,
      }
    );

    return rowsResult;
  }

  async evalTextPart(part) {
    const result = await this.nodeData.evaluateQuickSelectionValue(part);

    return [null, undefined].includes(result?.value)
      ? ""
      : this.toStringSync(result?.value);
  }

  async nestedEvaluateQuickSelectionValue(obj) {
    const keys = Object.keys(obj);

    let result = obj instanceof Array ? [] : {};
    await Promise.all(
      keys.map(async (key) => {
        if (key === "valueObj") {
          result = {
            ...result,
            ...(await this.nodeData
              .evaluateQuickSelectionValue(obj)
              .catch((e) => console.warn(e))),
          };
        } else if (typeof obj[key] === "object") {
          result[key] = await this.nestedEvaluateQuickSelectionValue(obj[key]);
        } else {
          result[key] = obj[key];
        }
      })
    );

    return result;
  }

  nestedToArray(obj, opt = {}) {
    const keys = Object.keys(obj);

    let result = obj instanceof Array ? [] : {};
    keys.map((key) => {
      if (key === "value") {
        result[key] = this.toValueArray(obj[key]).map((x) => {
          return opt.parseFloat && !isNaN(x?.value) && x?.value
            ? parseFloat(x?.value)
            : x?.value;
        });
      } else if (typeof obj[key] === "object") {
        result[key] = this.nestedToArray(obj[key], opt);
      } else {
        result[key] = obj[key];
      }
    });

    return result;
  }
}

export default RenderNode;
