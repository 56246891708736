class DomTreeNode {
  constructor(id, value, children = []) {
    this.id = id;
    this.value = value;
    this.children = children;
  }
}

class DomTree {
  constructor(tree, onUpdate) {
    this.tree = tree;
    this.onUpdate = onUpdate;
  }

  updateNodeValue(id, newValue) {
    const success = updateNodeValue(this.tree, id, newValue);

    if (success) {
      this.onUpdate(this.tree);
      return true;
    }

    return false;
  }

  addNode(parentId, newNode, index = 0) {
    const result = addNode(this.tree, parentId, newNode, index);
    this.onUpdate(result.tree);

    return result;
  }

  addNodeValue(parentId, newValue, index = 0) {
    const result = addNodeValue(this.tree, parentId, newValue, index);
    this.onUpdate(result.tree);

    return result;
  }

  moveNode(nodeId, destinationId, position) {
    const updatedTree = moveNode(this.tree, nodeId, destinationId, position);
    if (updatedTree) {
      this.tree = updatedTree;
      this.onUpdate(this.tree);
      return true;
    }

    return false;
  }

  deleteNodeById(id) {
    const result = deleteNodeById(this.tree, id);
    this.onUpdate(result.tree);
    return result;
  }

  findRecursiveIndices(targetId) {
    return findRecursiveIndices(this.tree, targetId);
  }

  findNodeAndParent(id) {
    return findNodeAndParent(this.tree, id);
  }

  findNodeAndParentByIndices(indices) {
    return findNodeAndParentByIndices(this.tree, indices);
  }

  findNodeAndParentsByIndices(indices) {
    return findNodeAndParentsByIndices(this.tree, indices);
  }

  isChild(parentIndices, childIndices) {
    if (parentIndices.length >= childIndices.length) {
      return false;
    }

    for (let i = 0; i < parentIndices.length; i++) {
      if (parentIndices[i] !== childIndices[i]) {
        return false;
      }
    }

    return true;
  }

  duplicateNodeWithUniqueIds(node, usedIds = []) {
    const clonedNode = new DomTreeNode(generateUniqueId(this.tree), node.value);

    usedIds = [...usedIds, clonedNode.id];

    if (node.children && node.children.length > 0) {
      clonedNode.children = node.children.map((child) =>
        this.duplicateNodeWithUniqueIds(child, usedIds)
      );
    }

    return clonedNode;
  }

  loopOverAllNodes(fn) {
    return processElementTree(this.tree, fn);
  }
}

function findRecursiveIndices(node, targetId, currentPath = []) {
  if (node === null) {
    return [];
  }

  if (node.id === targetId) {
    return [...currentPath];
  }

  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    const childPath = [...currentPath, i];
    const result = findRecursiveIndices(child, targetId, childPath);
    if (result.length > 0) {
      return result;
    }
  }

  return [];
}

function findNodeAndParent(node, id, parent = null) {
  if (node === null) {
    return { node: null, parent: null, index: -1 };
  }

  if (node.id === id) {
    const index = parent !== null ? parent.children.indexOf(node) : -1;
    return { node, parent, index };
  }

  for (let i = 0; i < node.children.length; i++) {
    const child = node.children[i];
    const result = findNodeAndParent(child, id, node);
    if (result.node !== null) {
      return result;
    }
  }

  return { node: null, parent: null, index: -1 };
}

function findNodeAndParentByIndices(
  node,
  indices,
  currentIndex = 0,
  parent = null
) {
  if (currentIndex === indices.length) {
    return { node, parent };
  }

  if (
    !node ||
    !node.children ||
    node.children.length <= indices[currentIndex]
  ) {
    return { node: null, parent: null };
  }

  const nextNode = node.children[indices[currentIndex]];

  return findNodeAndParentByIndices(nextNode, indices, currentIndex + 1, node);
}

function findNodeAndParentsByIndices(
  node,
  indices,
  currentIndex = 0,
  parents = []
) {
  if (currentIndex === indices.length) {
    return [...parents, node];
  }

  if (
    !node ||
    !node.children ||
    node.children.length <= indices[currentIndex]
  ) {
    return [];
  }

  const nextNode = node.children[indices[currentIndex]];

  return findNodeAndParentsByIndices(nextNode, indices, currentIndex + 1, [
    ...parents,
    node,
  ]);
}

function moveNode(tree, nodeId, destinationId, position) {
  const { node: movingNode, parent: movingParent } = findNodeAndParent(
    tree,
    nodeId
  );
  const { node: destinationNode, parent: destinationParent } =
    findNodeAndParent(tree, destinationId);

  if (!movingNode || !destinationNode) {
    console.log("Invalid node or destination.");
    return tree;
  }

  if (movingParent === null) {
    console.log("Cannot move the root node.");
    return tree;
  }

  // Remove the moving node from its current parent
  const movingIndex = movingParent.children.indexOf(movingNode);
  movingParent.children.splice(movingIndex, 1);

  // Determine the index where the moving node should be placed in the destination
  let destinationIndex;
  if (position === "before") {
    destinationIndex = destinationParent.children.indexOf(destinationNode);
  } else if (position === "after") {
    destinationIndex = destinationParent.children.indexOf(destinationNode) + 1;
  } else if (position === "inside") {
    destinationNode.children.push(movingNode);
    return tree;
  } else {
    console.log("Invalid position.");
    return tree;
  }

  // Insert the moving node at the determined index
  destinationParent.children.splice(destinationIndex, 0, movingNode);

  return tree;
}

function generateRandomString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

function processElementTree(element, fn) {
  fn(element);

  if (element.children) {
    for (let i = 0; i < element.children.length; ++i) {
      processElementTree(element.children[i], fn);
    }
  }
}

function generateUniqueId(tree, reserveIds = []) {
  const existingIds = [...reserveIds];
  const fn1 = (x) => existingIds.push(x?.id);
  processElementTree(tree, fn1);

  let iterationCount = 0;

  let length = 5;
  let random = generateRandomString(length);
  while (existingIds.includes(random)) {
    iterationCount++;
    random = generateRandomString(length + iterationCount);
  }

  return random;
}

function addNodeValue(tree, parentId, newValue, index = 0) {
  const { node: parentNode } = findNodeAndParent(tree, parentId);

  if (parentNode === null) {
    console.log("Parent node not found.");
    return;
  }

  const newId = generateUniqueId(tree);
  const newNode = new DomTreeNode(newId, newValue);
  parentNode.children.splice(index, 0, newNode);

  return { tree, newNode };
}

function addNode(tree, parentId, newNode, index = 0) {
  const { node: parentNode } = findNodeAndParent(tree, parentId);

  if (parentNode === null) {
    console.log("Parent node not found.");
    return;
  }

  parentNode.children.splice(index, 0, newNode);

  return { tree, newNode };
}

function updateNodeValue(tree, id, newValue) {
  const { node } = findNodeAndParent(tree, id);

  if (node) {
    node.value = newValue;
    return true;
  }

  return false;
}

function deleteNodeById(tree, id) {
  if (tree.id === id) {
    return null; // Return null to indicate deletion of the root node
  }

  tree.children = tree.children.filter(
    (child) => deleteNodeById(child, id) !== null
  );
  return tree;
}

export default {
  DomTree,
  DomTreeNode,
};
