export const convertAppfiltersToMongoFilterObject = async (filters) => {
  let filter = {};
  if (!filters || !filters.length) return filter;

  for (let i = 0; i < filters.length; ++i) {
    const x = filters[i];
    let xValue = x.value;

    if (x.type === "filterGroup") {
      let list = [];
      for (let i = 0; i < x.filters.length; i++) {
        const filter = x.filters[i];
        let obj = await convertAppfiltersToMongoFilterObject([filter]);
        list.push(obj);
      }

      if (["[OR]", "[AND]"].includes(x.name)) {
        let name = { "[OR]": "$or", "[AND]": "$and" }[x.name];
        filter = { ...filter, [name]: [...(filter[name] || []), ...list] };
      }

      continue;
    }

    if (!x.name) continue;

    // if (x.name === "_id" && typeof xValue === "string")
    //   try {
    //     xValue = ObjectId(xValue);
    //   } catch (e) {
    //     console.log("Error converting to object id ", e);
    //   }

    switch (x.condition) {
      case "neq":
        if (isNaN(xValue)) filter[x.name] = { $ne: xValue };
        else {
          let temp = isNaN(xValue)
            ? [{ [x.name]: { $ne: xValue } }]
            : [
                { [x.name]: { $ne: xValue } },
                { [x.name]: { $ne: parseFloat(xValue) } },
              ];
          if (!filter["$and"]) {
            filter["$and"] = temp;
          } else {
            filter["$and"] = [...filter["$and"], ...temp];
          }
        }
        break;
      case "gt":
        if (!isNaN(xValue)) xValue = parseFloat(xValue);
        filter[x.name] = { $gt: xValue };
        break;
      case "lt":
        if (!isNaN(xValue)) xValue = parseFloat(xValue);
        filter[x.name] = { $lt: xValue };
        break;
      case "gte":
        if (!isNaN(xValue)) xValue = parseFloat(xValue);
        filter[x.name] = { $gte: xValue };
        break;
      case "lte":
        if (!isNaN(xValue)) xValue = parseFloat(xValue);
        filter[x.name] = { $lte: xValue };
        break;
      case "Contain":
        let regexString = "^" + xValue + "|" + xValue + "$|, " + xValue + ",";
        filter[x.name] = { $regex: new RegExp(regexString) };
        break;
      case "containAnything":
        if (!xValue) {
          filter[x.name] = { $regex: /.+/ };
          break;
        }

        if (typeof xValue !== "string" && typeof xValue !== "number") break;

        xValue = xValue.toString().trim();
        xValue = xValue.replace(/ +/, " ");

        let words = xValue.split(" ");

        let containsAnythingStr = words[0];
        for (let i = 1; i < words.length; ++i) {
          let word = words[i];

          containsAnythingStr = containsAnythingStr.concat("|" + word);
        }

        let containsAnythingRegEx = new RegExp(containsAnythingStr, "i");
        filter[x.name] = { $regex: containsAnythingRegEx };
        break;
      default:
        if (isNaN(xValue)) filter[x.name] = xValue;
        else {
          let temp = [{ [x.name]: xValue }, { [x.name]: parseFloat(xValue) }];
          if (!filter["$or"]) {
            filter["$or"] = temp;
          } else {
            filter["$or"] = [...filter["$or"], ...temp];
          }
        }
    }
  }

  return filter;
};
