const procesDocument = async (document, existing, options = {}) => {
  let processedDocument = {};

  for (const key in document) {
    if (Object.hasOwnProperty.call(document, key)) {
      const {value, method} = document[key] || {};
      let existingValue = existing?.[key];

      let processedValue = value;
      if (
        method === 'add' &&
        existingValue &&
        ['number', 'string'].includes(typeof existingValue)
      ) {
        existingValue = existingValue.toString();
        processedValue = [...existingValue.split(','), value].join(',');
      }

      processedValue = isNaN(processedValue)
        ? processedValue
        : parseFloat(processedValue);

      processedDocument[key] = processedValue;
    }
  }

  if (!options.skipUpdatedAt) processedDocument.updatedAt = Date.now();

  return processedDocument;
};

export default procesDocument;
