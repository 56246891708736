import Axios from "axios";
import _ from "lodash";

import config from "../../Config";
import { store } from "../../store";
import { deviceId } from "../../Providers/deviceId";
import { getSocket } from "../../Providers/socket";
import { Encryption } from "../../appxolo-engine/modules/apiEncryption/frontend";

const MAX_REQUESTS_COUNT = 15;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

export const axios = Axios.create({});

/**
 * Axios Request Interceptor
 */
axios.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++;
        clearInterval(interval);
        resolve(config);
      }
    }, INTERVAL_MS);
  });
});

/**
 * Axios Response Interceptor
 */
axios.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.resolve(response);
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
    return Promise.reject(error);
  }
);

export const API_URL = config.url.api_url;
console.log({ API_URL });

const api = {};
const encryption = new Encryption({ api, config });

api.request = async (options) => {
  const { uri, params, body, method = "GET" } = options;
  let headers = await getHeaders(options);

  const requestData = {
    method,
    url: options.fullPath ? uri : API_URL + uri,
    headers,
    data: options.fullPath ? body : await encryption.encrypt(body),
    params: options.fullPath ? params : await encryption.encrypt(params),
    onUploadProgress: options.onUploadProgress,
  };

  if (config.debug.api)
    console.info("Sending api request", JSON.stringify(requestData, null, 4));

  return axios
    .request(requestData)
    .then((x) => {
      x = {
        ...x,
        data: options.fullPath ? x.data : decryptPayload(x.data),
      };

      return options.fullResponse ? x : x.data;
    })
    .catch((e) => {
      console.log(e);
      const status = e?.response?.status;
      const errorMsg =
        status === 500
          ? "Something Went Wrong"
          : e?.response?.data?.message || e.message || "Something Went Wrong";
      const error = new Error(errorMsg);
      error.status = status;
      throw error;
    })
    .finally((res) => {
      if (config.debug.api) console.log({ requestData, response: res });
    });
};

api.socket = async (uri, payload, options = {}) => {
  const cacheOptions = options?.cache;
  const cacheKey = "cache-appxolo-key-" + JSON.stringify(payload); // Create a unique key based on the payload

  if (cacheOptions?.useCache) {
    const dataPromise = api
      .executeSocket(uri, payload, options)
      .then((data) => {
        setWithExpiry(cacheKey, data, cacheOptions.expiry);

        return data;
      });

    const cachedData = await getWithExpiry(cacheKey);

    if (cacheOptions.returnLatest || !cachedData) {
      const data = await dataPromise;
      return data;
    } else {
      return cachedData;
    }
  } else {
    const data = await api.executeSocket(uri, payload, options);
    return data;
  }
};

api.executeSocket = async (uri, payload, options = {}) => {
  let socket = getSocket();
  if (!socket) throw new Error("Connection Error!");

  // if (config.isDev && config.debug.socketApi) await sleep(5000);

  let headers = await getHeaders(options);
  const socketPayload = await encryption.encrypt({
    headers,
    body: payload,
  });

  if (config.debug.socketApi)
    console.log("emiting socket event: ", { uri, socketPayload });

  return new Promise((resolve, reject) => {
    socket.emit(uri, socketPayload, (err, data) => {
      if (err) {
        console.warn("Error in socket request: ", {
          uri,
          err,
          socketPayload,
          payload,
        });
        if (typeof err === "string") return reject(new Error(err));
        else if (err?.message) return reject(err);
        else return reject(new Error("Something went wrong"));
      }

      return resolve(decryptPayload(data));
    });
  });
};

api.get = (uri, params, options = {}) => {
  return api.request({ ...options, uri, params, method: "GET" });
};

api.post = (uri, body, options = {}) => {
  return api.request({ ...options, uri, body, method: "POST" });
};

api.put = (uri, body, options = {}) => {
  return api.request({ ...options, uri, body, method: "PUT" });
};

api.delete = (uri, params, options = {}) => {
  return api.request({ ...options, uri, params, method: "DELETE" });
};

api.media = async (uri, data, options = {}) => {
  const headers = await getHeaders({
    ...options,
    headers: {
      ...(options.headers || {}),
      "Content-Type": "multipart/form-data",
    },
  });

  let formData = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const value = data[key];
      formData.append(key, value);
    }
  }

  return api.request({
    ...options,
    params: await encryption.encrypt(options.params),
    uri: options.fullPath ? uri : API_URL + uri,
    body: formData,
    method: "POST",
    headers,
    fullPath: true,
  });
};

api.getFileLink = (id, opt) => {
  const {
    height = 0,
    width = 0,
    video = false,
    thumbnail = false,
    params,
  } = opt || {};
  if (!id) return "";

  id = id._id || id;

  if (video) {
    return `${API_URL}v1/file/${id}`;
  }

  let query = `${height ? `height=${height || 0}` : ""}${
    width ? `&width=${width || 0}` : ``
  }${thumbnail ? "&thumbnail=1" : ""}`;

  if (params) {
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key];
        query = query ? query + "&" : query;
        query = query + `${key}=${value}`;
      }
    }
  }

  return `${API_URL}v1/file/${id}${query ? `?${query}` : ""}`;
};

api.getApiUrl = () => API_URL;

api.encryption = encryption;

export const getHeaders = async ({ headers = {} }) => {
  let state = store?.getState();

  return {
    "Content-Type": "application/json",
    "x-device-id": deviceId,
    "x-subdomain": window.location.host.split(".")[0],
    "x-project-id": state.pState.APP?.projectData?._id,
    "x-device-platform": "web",
    ...headers,
  };
};

let sleep = (ms) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );
};

const setWithExpiry = async (key, value, ttl) => {
  await removeExpiredItems();

  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  await localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = async (key) => {
  const itemStr = await localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    await localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const removeExpiredItems = async () => {
  const now = new Date();
  const regex = new RegExp("^cache-appxolo-key-.+");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (regex.test(key)) {
      const itemStr = await localStorage.getItem(key);
      if (itemStr) {
        const item = JSON.parse(itemStr);
        if (item.expiry && now.getTime() > item.expiry) {
          await localStorage.removeItem(key);
          i--; // Adjust index after removing item
        }
      }
    }
  }
};

const decryptPayload = (data) => {
  if (!data) return data;

  let responseData = { ...data };

  if (responseData.e && responseData.i) {
    const decryptedStr = encryption.decrypt(
      responseData.e,
      responseData.i,
      config.encryption.apiSecret
    );

    const decrypted = JSON.parse(decryptedStr);

    delete responseData.i;
    delete responseData.e;

    responseData = { ...responseData, ...decrypted };
  }

  return responseData;
};

export default api;
