import api from "../Api/api";
import PersistedActions from "../..//Stores/redux/Persisted/Actions";
import callModule from "../../Modules/call/call-module";
import { store } from "../../store";

var subcribedFuncs = {}; // {eventStr: {randomId: func}}

export const subscribeSocketEvent = (eventStr, func) => {
  if (typeof func != "function") return null;

  if (!subcribedFuncs[eventStr]) {
    subcribedFuncs[eventStr] = {};
  }

  let id = Date.now().toString() + Math.random();

  subcribedFuncs[eventStr][id] = func;

  // console.info(
  //   "subscribing subcribedFuncs",
  //   eventStr,
  //   subcribedFuncs[eventStr]
  // );

  return id;
};

export const unsubscribeSocketEvent = (eventStr, id) => {
  if (!id) return;

  if (!subcribedFuncs[eventStr]?.[id]) {
    return;
  } else {
    delete subcribedFuncs[eventStr][id];
  }

  // console.info(
  //   "unsubscribing subcribedFuncs",
  //   eventStr,
  //   id,
  //   subcribedFuncs[eventStr]
  // );
  return;
};

const dispatchToSubcribers = (eventStr, data) => {
  if (subcribedFuncs[eventStr]) {
    let funcs = Object.values(subcribedFuncs[eventStr]);

    for (let i = 0; i < funcs.length; i++) {
      const func = funcs[i];

      try {
        if (func && typeof func === "function") {
          func(data);
        }
      } catch (e) {
        console.info(e);
      }
    }
  }
};

const socketListeners = async (socket, opt = {}) => {
  socket.on("v1/project/update", async (data) => {
    const selectedProject = store?.getState()?.pState?.APP?.projectData;
    if (selectedProject?._id?.toString() === data?.projectId?.toString()) {
      console.log("Getting project data");

      const { project: projectData } = await api.get(
        "v1/app/project/" + selectedProject?._id
      );

      store.dispatch(
        PersistedActions.setPScreenState("APP", {
          projectData,
          projectUpdated: Date.now(),
        })
      );
    }

    dispatchToSubcribers("v1/project/update", data);
  });

  socket.on("/changeStream/table", async (data) => {
    console.log("/changeStream/table", data);
    dispatchToSubcribers("/changeStream/table", data);
  });

  socket.on("v1/database/write/dbsync", async (data) => {
    console.log("v1/database/write/dbsync", data);
    dispatchToSubcribers("v1/database/write/dbsync", data);
  });

  socket.on("v1/webrtc/call", async (data) => {
    try {
      callModule.onCallRequest(data).catch(console.log);

      dispatchToSubcribers("v1/webrtc/call", data);
    } catch (e) {
      console.warn(e);
    }
  });

  socket.on("v1/webrtc/joinroom", async (data) => {
    try {
      dispatchToSubcribers("v1/webrtc/joinroom", data);
    } catch (e) {
      console.warn(e);
    }
  });

  socket.on("v1/webrtc/candidate", async (data) => {
    try {
      dispatchToSubcribers("v1/webrtc/candidate", data);
    } catch (e) {
      console.warn(e);
    }
  });

  socket.on("v1/webrtc/offer", async (data) => {
    try {
      dispatchToSubcribers("v1/webrtc/offer", data);
    } catch (e) {
      console.warn(e);
    }
  });

  socket.on("v1/webrtc/answer", async (data) => {
    try {
      dispatchToSubcribers("v1/webrtc/answer", data);
    } catch (e) {
      console.warn(e);
    }
  });

  socket.on("v1/webrtc/hangup", async (data) => {
    try {
      console.info("v1/webrtc/hangup", data);

      dispatchToSubcribers("v1/webrtc/hangup", data);
    } catch (e) {
      console.warn(e);
    }
  });
};

var joinedRoomCounts = {};
export const joinSocketRoom = (room) => {
  joinedRoomCounts[room] = (joinedRoomCounts[room] || 0) + 1;

  return api.socket("v1/socket/join", { room });
};
export const leavSocketRoom = (room, opt = {}) => {
  if (opt.all && joinedRoomCounts[room]) {
    delete joinedRoomCounts[room];
  } else if (joinedRoomCounts[room]) {
    joinedRoomCounts[room] = joinedRoomCounts[room] - 1;
  }

  if (joinedRoomCounts[room] <= 0) {
    delete joinedRoomCounts[room];
  }

  if (!joinedRoomCounts[room]) {
    return api.socket("v1/socket/leave", { room });
  }
};

export const socketService = {
  socketApi: api.socket,
  subscribeSocketEvent,
  unsubscribeSocketEvent,
  joinSocketRoom,
  leavSocketRoom,
};

export default socketListeners;
