import React from "react";
import ScreenComponent from "./ScreenComponent";

const Modal = (props) => {
  if (!props.visible) return null;
  return (
    <div style={Object.assign({}, styleObj.popup, props.style)}>
      <div style={styleObj.xClose} onClick={() => props.setModal(false)}>
        X
      </div>
      <div style={styleObj.holder}>
        <ScreenComponent {...props} />
      </div>
    </div>
  );
};

const styleObj = {
  popup: {
    position: "fixed",
    borderRadius: "7px",
    background: "#efefef",
    margin: "0 auto 0",
    padding: "13px",
    // here it comes
    width: "80%",
    height: "90%",
    overFlow: "auto",
    top: "50px",
    left: "50%",
    marginLeft: "-40%", // 1/2 width
    // marginTop: "-300px", // 1/2 height
    boxShadow: "10px 10px 5px grey",
  },
  xClose: {
    float: "right",
    cursor: "pointer",
    backgroundColor: "palevioletred",
    color: "white",
    padding: "0 5px",
    margin: "1px 0px 10px",
    borderRadius: "3px",
    fontWeight: "bold",
  },
  holder: { height: "100%" },
};

export default Modal;
