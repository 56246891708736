import { v4 as uuidv4 } from "uuid";

import LocalDatabase from "../../appxolo-engine/modules/database/LocalDatabase";
import Storage from "./storage";

const dbs = {};

const createDatabaseInstance = async (dbId) => {
  const database = new LocalDatabase({ dbId, Storage, createUniqueId: uuidv4 });
  await database.load();

  dbs[database.dbId] = database;

  return database;
};

const handleEvent = function(event) {
  if (dbs[event.key]) {
    dbs[event.key].load();
  }
}

window.addEventListener('storage', handleEvent);

export default createDatabaseInstance;
