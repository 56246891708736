class ExecuteTrigger {
  constructor(props) {
    this.props = props;

    this.nodeData = new props.NodeData();
    this.nodeData.load(props);
  }

  async executeTrigger(trigger, options) {
    const { tab: linkingTab } = await this.nodeData.getConditionSatisfiedData(
      {
        ...(trigger.data || {}),
        dataType: "conditional",
      },
      options
    );

    await this.nodeData
      .handleLinkings(linkingTab?.linkings, options)
      .catch((e) => console.warn("Error handeling triggers: ", e.message));
  }
}

export default ExecuteTrigger;
