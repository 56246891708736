import React from "react";
import AppModal from "../../Components/Models/app-modal";
import PaypalPay from "./PaypalPay";
import StripePay from "./StripePay";

const PaymentModalIntermediate = (props) => {
  const { paymentModal, setPaymentModal } = props;
  if (!paymentModal || !paymentModal.display) return null;

  const {
    callback,
    display,
    paymentMethod,
    paymentMethods,
    price,
    paymentType,
    productId,
  } = paymentModal;

  let visible =
    display &&
    paymentMethods?.length &&
    (paymentMethods.includes("paypal") || paymentMethods.includes("stripe"));

  return (
    <AppModal
      {...{
        showHeader: false,
        showSubmitBtn: false,
        handleClose: () => setPaymentModal({ display: false }),
        visible: visible,
      }}
    >
      <div>
        <div>Product Id: {productId}</div>
        <div>Price: {price} USD</div>

        {paymentMethods?.includes("stripe") ? (
          <StripePay
            {...{
              callback: (err, data) => {
                setPaymentModal({ display: false });
                callback(err, data, "stripe");
              },
              onCancel: () => setPaymentModal({ display: false }),
              price: price,
              productId: productId,
              projectData: props.projectData,
              paymentType,
            }}
          />
        ) : null}

        {paymentMethods?.includes("paypal") ? (
          <PaypalPay
            {...{
              callback: async (err, data) => {
                setPaymentModal({ display: false });
                return callback(err, data, "paypal");
              },
              onCancel: () => setPaymentModal({ display: false }),
              price: price,
              productId: productId,
              projectData: props.projectData,
              paymentType,
            }}
          />
        ) : null}
      </div>
    </AppModal>
  );
};

const PaymentModalDirect = (props) => {
  const { paymentModal, setPaymentModal } = props;
  if (!paymentModal || !paymentModal.display) return null;

  const { callback, display, paymentMethod, price, paymentType, productId } =
    paymentModal;

  return (
    <AppModal
      {...{
        showHeader: false,
        showSubmitBtn: false,
        handleClose: () => setPaymentModal({ display: false }),
        // visible: [ 'paypal','stripe','inAppPurchase'].includes(visibleModal),
        visible: display && ["paypal", "stripe"].includes(paymentMethod),
      }}
    >
      {paymentMethod == "stripe" ? (
        <StripePay
          {...{
            callback: (err, data) => {
              setPaymentModal({ display: false });
              callback(err, data, "stripe");
            },
            onCancel: () => setPaymentModal({ display: false }),
            price: price,
            productId: productId,
            projectData: props.projectData,
            paymentType,
          }}
        />
      ) : paymentMethod == "paypal" ? (
        <PaypalPay
          {...{
            callback: async (err, data) => {
              setPaymentModal({ display: false });
              return callback(err, data, "paypal");
            },
            onCancel: () => setPaymentModal({ display: false }),
            price: price,
            productId: productId,
            projectData: props.projectData,
            paymentType,
          }}
        />
      ) : null}
    </AppModal>
  );
};

export default PaymentModalIntermediate;
