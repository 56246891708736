import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Config from "../Config";
import NotFound404 from "../Views/Common/not-found-404";
import ProjectScreen from "../Views/ProjectScreen/ProjectScreen";
// import WorkInProgress from "../Views/Common/work-in-progress";

function AppNavigator({ screens }) {
  return (
    <Router basename={Config.relativePath}>
      <Routes>
        {screens.map((screen) => (
          <Route
            key={screen._id}
            path={screen.data?.url || screen._id}
            element={<ProjectScreen screen={screen} key={screen._id} />}
          />
        ))}

        <Route path="*" element={<NotFound404 screens={screens} />} />
      </Routes>
    </Router>
  );
}

export default AppNavigator;
