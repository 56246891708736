import React from "react";
import _ from "lodash";

const TextInput = (props) => {
  return props.multiline ? (
    <textarea
      {...{
        onChange: (e) => props.onChangeText?.(e.target.value),
        ..._.omit(props, "onChangeText", "multiline"),
      }}
    />
  ) : (
    <input
      {...{
        onChange: (e) => props.onChangeText?.(e.target.value),
        ..._.omit(props, "onChangeText", "multiline"),
      }}
    />
  );
};

export default TextInput;
