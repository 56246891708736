import React, { Component } from "react";

class LongPressButton extends Component {
  constructor(props) {
    super(props);
    this.longPressTimeout = null;
    this.pressed = false;

    this.shortPressTimer = null;
    this.longPressTimer = null;
  }

  onLongPress() {
    clearTimeout(this.longPressTimer);
    this.longPressTimer = setTimeout(() => {
      this.props.onLongPress();
    }, 30);
  }

  onShortPress() {
    clearTimeout(this.shortPressTimer);
    this.shortPressTimer = setTimeout(() => {
      this.props.onShortPress();
    }, 30);
  }

  handleMouseDown = () => {
    this.pressed = true;
    this.longPressTimeout = setTimeout(() => {
      if (this.pressed) {
        this.onLongPress();
      }
      this.pressed = false;
    }, 500); // 500ms for a long press
  };

  handleMouseUp = () => {
    clearTimeout(this.longPressTimeout);

    if (this.pressed) {
      this.onShortPress();
      this.pressed = false;
    }
  };

  handleMouseLeave = () => {
    this.props.onMouseLeave();
    clearTimeout(this.longPressTimeout);
    if (this.pressed) {
      this.onShortPress();
      this.pressed = false;
    }
  };

  componentWillUnmount() {
    clearTimeout(this.longPressTimeout);
  }

  render() {
    return (
      <div
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
        onTouchStart={this.handleMouseDown}
        onTouchEnd={this.handleMouseUp}
        onMouseEnter={this.props.onMouseEnter}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default LongPressButton;
