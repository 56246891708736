import React from "react";
import Redirect from "../../Components/Navigation/Redirect";
const NotFound404 = ({ screens }) => {
  if (screens?.[0]?.name)
    return (
      <Redirect to={"/" + (screens?.[0]?.data?.url || screens?.[0]?._id)} />
    );
  else return <div>404 Not Found</div>;
};

export default NotFound404;
