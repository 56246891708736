import React from "react";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PActions from "../../../../Stores/redux/Persisted/Actions";
import UnpActions from "../../../../Stores/redux/Unpersisted/Actions";

const WebrtcMedia = (props) => {
  const { domNode, activeTab, onGoingCall, elementDataStore } = props;
  if (!onGoingCall) return null;

  const activeTabStyle =
    activeTab?.styleData?.[domNode?.value?.elementType] || {};

  // const data = elementDataStore;
  // const deviceId = data?.deviceId?.value;

  let streamData;

  // const deviceId = elementDataStore?.value;

  // if (deviceId === currentDeviceId)
  //   streamData = { stream: onGoingCall.localStream, isLocal: true };

  // if (!streamData)
  //   streamData = onGoingCall.remoteStreams?.find(
  //     (x) => x.peer?.deviceId === deviceId
  //   );

  let participantId = elementDataStore?.participantId?.value;
  let mode = (elementDataStore?.mode || { value: "video" })?.value
    .toString()
    .toLowerCase()
    .trim();

  if (participantId === onGoingCall.participantId)
    streamData = { stream: onGoingCall.localStream, isLocal: true };

  if (!streamData)
    streamData = onGoingCall.remoteStreams?.find(
      (x) => x.peer?.participantId === participantId
    );

  if (!streamData?.stream) return null;

  return (
    <>
      {mode === "audio" ? <AudioVisualizer stream={streamData.stream} /> : null}
      <VideoView
        stream={streamData.stream}
        muted={!!streamData?.isLocal}
        style={{
          objectFit: "cover",
          width: "100%",
          ...activeTabStyle,
          display: mode === "audio" ? "none" : "block",
        }}
      />
    </>
  );
};

class VideoView extends React.Component {
  state = {
    visible: !!this.props.stream,
  };
  videoView = React.createRef(null);

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.stream && this.props.stream) ||
      prevProps.stream !== this.props.stream
    ) {
      this.load();
    }
  }

  load() {
    // Pause the previous stream
    if (this.videoView.current?.srcObject) {
      this.videoView.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setTimeout(() => {
      this.videoView.current.srcObject = this.props.stream;
      this.videoView.current.onloadedmetadata = (e) => {
        this.videoView.current.play();
      };
    }, 0);
  }

  render() {
    const {
      props: { muted = false, style = {} },
    } = this;

    return (
      <video
        ref={this.videoView}
        autoPlay
        muted={muted}
        style={{ maxWidth: "100%", maxHeight: "100%", ...style }}
      ></video>
    );
  }
}

const SCREEN_NAME = "CALL_SCREEN";
const mapStateToProps = (state) => ({
  onGoingCall: state.vState[SCREEN_NAME]?.onGoingCall,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const AudioVisualizer = ({ stream }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!stream) return;

    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);

    analyser.fftSize = 256; // Adjust for smoother or more detailed visualization
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const canvas = canvasRef.current;
    const canvasCtx = canvas?.getContext("2d");

    const draw = () => {
      requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 1;
      let barHeight;
      let x = 0;

      const centerY = canvas.height / 2;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 4 || 0.25;

        canvasCtx.fillStyle = `rgb(0, 50, ${barHeight * 5 + 100})`;

        canvasCtx.fillRect(x, centerY - barHeight, barWidth, barHeight * 2);

        x += barWidth + 1;
      }
    };

    draw();

    return () => {
      audioContext.close();
    };
  }, [stream]);

  return <canvas ref={canvasRef} width={800} height={500} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(WebrtcMedia);
