import React from "react";
import _ from "lodash";

import Flex from "./Flex";
import utils from "./utils";
import AppModal from "../Models/app-modal";

class Calendar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.weekdays = utils.getWeekDays();
    this.today = new Date(new Date().setHours(0, 0, 0, 0));

    this.scrollRef = React.createRef(null);
    this.monthLoadOffset = 18;
    this.weekLoadLimit = this.monthLoadOffset * 2 * 4;

    this.state = {
      selectedDate: null,
      rangeStart: null,
      rangeEnd: null,
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      weeks: [],
      modalVisible: false,
    };
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value) ||
      JSON.stringify(this.props.pickerConfig) !==
        JSON.stringify(prevProps.pickerConfig)
    ) {
      this.reset();
    }
  }

  reset() {
    this.isRangeSelector = this.props.pickerConfig.selectType === "rangeSelect";

    const value = this.props.value || {};

    const intiialDate =
      (this.isRangeSelector
        ? value.rangeStart || value.rangeEnd
        : value.value) || new Date();

    const currentDate = utils.parseDate(intiialDate);

    const state = {
      ...this.state,
      selectedDate:
        !this.isRangeSelector && utils.parseDate(value.value)
          ? new Date(utils.parseDate(value.value).setHours(0, 0, 0, 0))
          : null,
      rangeStart:
        this.isRangeSelector && utils.parseDate(value.rangeStart)
          ? new Date(utils.parseDate(value.rangeStart).setHours(0, 0, 0, 0))
          : null,
      rangeEnd:
        this.isRangeSelector && utils.parseDate(value.rangeEnd)
          ? new Date(utils.parseDate(value.rangeEnd).setHours(0, 0, 0, 0))
          : null,
      selectedDay: currentDate.getDate(),
      selectedMonth: currentDate.getMonth(),
      selectedYear: currentDate.getFullYear(),
    };

    this.setState(state, () => this.load());
  }

  load() {
    const refDate = new Date(
      this.state.selectedYear,
      this.state.selectedMonth,
      this.state.selectedDay
    );

    const weeks =
      this.props.pickerConfig.datepickerType === "bar"
        ? this.loadSingleWeek(refDate)
        : this.props.pickerConfig.datepickerType === "infinite"
        ? this.loadWeeksForInfinite(refDate)
        : this.loadWeeksOfMonth(refDate);

    this.setState({ weeks }, () => {
      if (
        this.props.pickerConfig.datepickerType === "infinite" &&
        this.scrollRef.current
      ) {
        const weekHeight = this.scrollRef.current.scrollHeight / weeks.length;
        const desiredScrollPosition =
          weekHeight * Math.floor((this.monthLoadOffset * 30) / 7);
        this.scrollRef.current.scrollTop = desiredScrollPosition;
      }
    });
  }

  loadSingleWeek(date) {
    return this.loadWeeks(date, 1);
  }

  loadWeeksForInfinite(date) {
    const prevMonth = new Date(date).setMonth(
      date.getMonth() - this.monthLoadOffset
    );
    const firstDayOfMonth = utils.getFirstDayOfMonth(prevMonth);

    return this.loadWeeks(firstDayOfMonth, this.weekLoadLimit);
  }

  appendWeeksForInfinite = _.debounce(
    function (appendOnTop) {
      let weeks = [];
      if (appendOnTop) {
        const date = this.state.weeks[0][0].date;
        weeks = this.loadWeeksForInfinite(date);
      } else {
        const date = this.state.weeks[this.state.weeks.length - 1][0].date;
        weeks = this.loadWeeksForInfinite(date);
      }

      this.setState({ weeks });
    },
    1000,
    { leading: true }
  );

  loadWeeksOfMonth(date) {
    const firstDayOfMonth = utils.getFirstDayOfMonth(date);
    const currentMonth = firstDayOfMonth.getMonth();
    const weeks = this.loadWeeks(firstDayOfMonth, 10).filter(
      (week) =>
        currentMonth - 1 <= week[0].date.getMonth() &&
        week[0].date.getMonth() <= currentMonth
    );
    return weeks;
  }

  loadWeeks(date, n = 1) {
    const weeks = [];
    const firstDay = utils.getFirstDayOfWeek(date);

    let baseDate = new Date(firstDay);

    for (let i = 0; i < n; i++) {
      const week = [];

      for (let j = 0; j < 7; j++) {
        week.push(this.preProcessDate(new Date(baseDate)));
        baseDate.setDate(baseDate.getDate() + 1);
      }
      weeks.push(week);
    }

    return weeks;
  }

  preProcessDate(date) {
    const {
      state: { selectedMonth, selectedDate, rangeStart, rangeEnd },
      isRangeSelector,
    } = this;

    return {
      date,
      isToday: this.today.getTime() === date.getTime(),
      isDisabledDate: this.isDisabledDate(date),
    };
  }

  getDateSelectionState(date) {
    const {
      state: { selectedMonth, selectedDate, rangeStart, rangeEnd },
      isRangeSelector,
    } = this;

    if (isRangeSelector) {
      return rangeStart && rangeStart.getTime() === date.getTime()
        ? "rangeStart"
        : rangeEnd && rangeEnd.getTime() === date.getTime()
        ? "rangeEnd"
        : rangeStart &&
          rangeEnd &&
          rangeStart.getTime() < date.getTime() &&
          date.getTime() < rangeEnd.getTime()
        ? "inRange"
        : null;
    } else {
      return selectedDate?.getTime() === date.getTime() ? "selected" : null;
    }
  }

  updateDay(n) {
    this.setState({ selectedDay: this.state.selectedDay + n }, () =>
      this.load()
    );
  }
  updateMonth(n) {
    this.setState({ selectedMonth: this.state.selectedMonth + n }, () =>
      this.load()
    );
  }
  updateYear(n) {
    this.setState({ selectedYear: this.state.selectedYear + n }, () =>
      this.load()
    );
  }

  isDisabledDate(date) {
    const ms = date.getTime();
    const { minDate, maxDate, disabledDates, allowedDates } =
      this.props.pickerConfig || {};

    if (allowedDates?.includes(ms)) return false;
    if (disabledDates?.includes(ms)) return true;
    if ((minDate || minDate === 0) && ms < minDate) return true;
    if ((maxDate || maxDate === 0) && ms > maxDate) return true;
    return false;
  }

  onChange(date) {
    const updateProps = (x) => {
      this.props.onChange(x);
      this.setState({ modalVisible: false });
    };

    if (this.isRangeSelector) {
      const { rangeStart, rangeEnd } = this.state;

      if (rangeStart && rangeEnd) {
        this.setState({ rangeStart: date, rangeEnd: null });
      } else if (rangeStart) {
        if (rangeStart.getTime() > date.getTime()) {
          this.setState({ rangeStart: date, rangeEnd: rangeStart }, () =>
            updateProps({
              rangeStart: utils.toDateOnlyFormat(this.state.rangeStart),
              rangeEnd: utils.toDateOnlyFormat(this.state.rangeEnd),
            })
          );
        } else {
          this.setState({ rangeEnd: date }, () =>
            updateProps({
              rangeStart: utils.toDateOnlyFormat(this.state.rangeStart),
              rangeEnd: utils.toDateOnlyFormat(this.state.rangeEnd),
            })
          );
        }
      } else {
        this.setState({ rangeStart: date });
      }
    } else {
      updateProps({ value: utils.toDateOnlyFormat(date) });
    }
  }

  getWeekHeight() {}

  handleInfiniteDatepickerScroll = _.debounce(function (e) {
    const scrollTop = this.scrollRef.current.scrollTop;
    const scrollHeight = this.scrollRef.current.scrollHeight;
    const weeks = this.state.weeks;
    const weekHeight = scrollHeight / weeks?.length;
    const focusedWeekIndex = Math.floor(scrollTop / weekHeight);
    const refDate = weeks[focusedWeekIndex + 2]?.[6]?.date;
    if (refDate) {
      this.setState({
        selectedDay: refDate.getDate(),
        selectedMonth: refDate.getMonth(),
        selectedYear: refDate.getFullYear(),
      });
    }

    const isSrollTopReached = scrollTop < 400;
    const isSrollBottomReached =
      scrollHeight - scrollTop - this.scrollRef.current.clientHeight < 400;

    if (isSrollBottomReached || isSrollTopReached) {
      this.appendWeeksForInfinite(isSrollTopReached);
    }
  }, 20);

  getDateBoxStyle({ date, isToday, isDisabledDate }) {
    const dateSelection = this.getDateSelectionState(date);
    const isSameMonth =
      date.getMonth() === this.state.selectedMonth &&
      date.getFullYear() === this.state.selectedYear;

    return {
      padding: "10%",
      margin: "0px",
      fontSize: 12,
      color: "#000",
      textAlign: "center",
      cursor: "pointer",
      ...(isSameMonth
        ? { backgroundColor: "#fff" }
        : { backgroundColor: "#f2f2f2" }),
      ...(isToday ? { fontWeight: "800" } : {}),
      ...(dateSelection === "selected"
        ? { backgroundColor: "#aaf" }
        : dateSelection === "rangeStart"
        ? {
            backgroundColor: "#aaf",
            borderTopLeftRadius: "100px",
            borderBottomLeftRadius: "100px",
          }
        : dateSelection === "rangeEnd"
        ? {
            backgroundColor: "#aaf",
            borderTopRightRadius: "100px",
            borderBottomRightRadius: "100px",
          }
        : dateSelection === "inRange"
        ? { backgroundColor: "#aaf" }
        : {}),
      ...(isDisabledDate ? { color: "#aaa", cursor: "not-allowed" } : {}),
    };
  }

  renderWeekDays() {
    return (
      <Flex count={7}>
        {this.weekdays.map((x) => (
          <div key={x}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "800",
                color: "#000",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              {x}
            </div>
          </div>
        ))}
      </Flex>
    );
  }

  renderMonthTitle() {
    const {
      state: { selectedDay, selectedMonth, selectedYear },
    } = this;
    const date = new Date(selectedYear, selectedMonth, selectedDay);
    const monthTitle = date.toLocaleString("default", { month: "long" });

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{ cursor: "pointer", padding: "5px" }}
          onClick={() => this.updateYear(-1)}
        >
          ◀◀
        </div>
        <div
          style={{ cursor: "pointer", padding: "5px" }}
          onClick={() => this.updateMonth(-1)}
        >
          ◀
        </div>
        <div style={{ minWidth: "50%", textAlign: "center" }}>
          {monthTitle}, {selectedYear}
        </div>
        <div
          style={{ cursor: "pointer", padding: "5px" }}
          onClick={() => this.updateMonth(1)}
        >
          ▶
        </div>
        <div
          style={{ cursor: "pointer", padding: "5px" }}
          onClick={() => this.updateYear(1)}
        >
          ▶▶
        </div>
      </div>
    );
  }

  renderWeek(week) {
    return (
      <Flex key={week[0].date.getTime()} count={7}>
        {week.map((item) => {
          const { date, isDisabledDate } = item;

          return (
            <div key={date.getTime()}>
              <div
                style={this.getDateBoxStyle(item)}
                onClick={() => (isDisabledDate ? {} : this.onChange(date))}
              >
                {date.getDate()}
              </div>
            </div>
          );
        })}
      </Flex>
    );
  }

  renderMonthView(opt = {}) {
    const {
      state: { weeks },
    } = this;

    return (
      <div
        style={Object.assign({}, opt.style, {
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        })}
      >
        {this.renderMonthTitle()}
        {this.renderWeekDays()}
        <div style={{ backgroundColor: "#f8f8f8", padding: "5px" }}>
          {weeks.map((week) => this.renderWeek(week))}
        </div>
      </div>
    );
  }

  datepickerTypeSelect() {
    const {
      props: { style, pickerConfig },
      state: { selectedDate, rangeStart, rangeEnd, modalVisible },
    } = this;
    return (
      <>
        <div
          style={Object.assign({}, style, {
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          })}
          onClick={() => this.setState({ modalVisible: true })}
        >
          {pickerConfig.selectType === "rangeSelect" ? (
            <span>{`${
              rangeStart ? utils.toDateOnlyFormat(rangeStart) : "yyyy-mm-dd"
            }  -  ${
              rangeEnd ? utils.toDateOnlyFormat(rangeEnd) : "yyyy-mm-dd"
            }`}</span>
          ) : (
            <span>{`${
              selectedDate ? utils.toDateOnlyFormat(selectedDate) : "yyyy-mm-dd"
            }`}</span>
          )}
          <div>▼</div>
        </div>
        <AppModal
          {...{
            title: "",
            showFooter: false,
            showHeader: false,
            visible: modalVisible,
            handleClose: () => this.setState({ modalVisible: false }),
            backdrop: "dynamic",
          }}
        >
          <div>
            {this.renderMonthView({
              style: { width: "100%", maxWidth: "500px" },
            })}
          </div>
        </AppModal>
      </>
    );
  }

  datepickerTypeBar(opt = {}) {
    const {
      state: { weeks },
    } = this;

    return (
      <div
        style={Object.assign({}, opt.style, {
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          backgroundColor: "#fff",
        })}
      >
        {this.renderMonthTitle()}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "1%",
            paddingRight: "1%",
            alignItems: "center",
          }}
        >
          <div
            style={{ cursor: "pointer", padding: "5px" }}
            onClick={() => this.updateDay(-7)}
          >
            ◀
          </div>
          <div style={{ flex: 1 }}>
            {this.renderWeekDays()}
            <div style={{ backgroundColor: "#f8f8f8" }}>
              {weeks.map((week) => this.renderWeek(week))}
            </div>
          </div>
          <div
            style={{ cursor: "pointer", padding: "5px" }}
            onClick={() => this.updateDay(7)}
          >
            ▶
          </div>
        </div>
      </div>
    );
  }

  datepickerTypeInfinite(opt = {}) {
    const {
      state: { weeks },
    } = this;

    return (
      <div
        style={Object.assign({ height: "350px" }, opt.style, {
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          backgroundColor: "#fff",
        })}
      >
        {this.renderMonthTitle()}
        {this.renderWeekDays()}
        <div
          style={{ flex: 1, overflowY: "scroll" }}
          onScroll={this.handleInfiniteDatepickerScroll.bind(this)}
          ref={this.scrollRef}
        >
          <div style={{ backgroundColor: "#f8f8f8" }}>
            {weeks.map((week) => this.renderWeek(week))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.pickerConfig.datepickerType) {
      case "open":
        return this.renderMonthView({ style: this.props.style });
      case "bar":
        return this.datepickerTypeBar({ style: this.props.style });
      case "infinite":
        return this.datepickerTypeInfinite({ style: this.props.style });
      case "select":
      default:
        return this.datepickerTypeSelect();
    }
  }
}

export default Calendar;
