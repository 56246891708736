const isDev = process.env.NODE_ENV === "development";

const config = {
  appName: "appxolo-web-app",
  relativePath: "",

  url: {
    api_url: process.env.REACT_APP_APIURL || "/api/",
    socket_url: process.env.REACT_APP_SOCKET_URL || "localhost",
  },

  google: {
    api_key: process.env.REACT_APP_GOOGLE_API_KEY,
  },

  webrtc: {
    iceServers: [
      {
        urls: [
          "stun:stun1.l.google.com:19302",
          "stun:stun2.l.google.com:19305",
        ],
      },
      {
        urls: ["stun:65.1.226.43"],
      },
      {
        urls: ["turn:65.1.226.43"],
        username: "guest",
        credential: "somepassword",
      },
    ],
  },

  debug: {
    api: true && isDev,
    socketApi: true && isDev,
  },

  encryption: { apiSecret: process.env.REACT_APP_API_SECRET },

  isDev,
};

export default config;
