// import navigationModule from '../../../../Modules/navigation/navigationModule';
import RenderTextInput from "./RenderTextInput";
import { v4 as uuidv4 } from "uuid";

class RenderLocationInput extends RenderTextInput {
  constructor(props) {
    super(props);

    this.API_CALL_INTERVAL = 100;
    this.API_SESSION_TIMEOUT_SECOND = 60;

    this.state = {
      focused: false,
      suggestions: null,
    };
  }

  onChangeValue(value) {
    clearTimeout(this.inputBufferTimer1);
    clearTimeout(this.inputBufferTimer2);
    this.setState({ ts: Date.now(), inputBuffer: value });
    this.inputBufferTimer1 = setTimeout(() => {
      this.props.setElementDataStore({
        value,
        valueType: null,
        valueObj: null,
      });
      this.inputBufferTimer2 = setTimeout(() => {
        this.setState({ inputBuffer: "" });
      }, 100);
    }, 50);
  }

  // navigateToLocationInputScreen() {
  //   navigationModule.navigate('/app/location/search', {
  //     q: this.value,
  //     onChange: data =>
  //       this.onChange({
  //         value: data.description,
  //         valueType: 'location',
  //         valueObj: data,
  //       }),
  //   });
  // }

  timer = null;
  handleSearch(q) {
    clearTimeout(this.timer);
    this.setState({ inputBuffer: q });

    this.timer = setTimeout(
      () => this.getSuggestions(q),
      this.API_CALL_INTERVAL
    );
  }

  session = {
    token: uuidv4(),
    expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
  };

  async search(input) {
    if (this.session.expires < Date.now()) {
      this.session = {
        token: uuidv4(),
        expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
      };
    }

    return this.props.api
      .socket("v1/app/places/placesAutoComplete", {
        input,
        sessiontoken: this.session.token,
      })
      .then((data) => {
        if (data?.status === "OK" && data.predictions) {
          return data.predictions;
        } else {
          throw new Error("Error getting place suggestions");
        }
      })
      .catch((e) => console.error("Error getting place details: " + e.message));
  }

  async getSuggestions(q) {
    if (!q) {
      this.onChange({
        value: "",
        valueType: "location",
        valueObj: null,
      });
      this.setState({ suggestions: null, inputBuffer: null });
    } else {
      const suggestions = await this.search(q).catch((e) => {
        console.warn(e);
        return [];
      });

      this.setState({
        suggestions,
      });
    }
  }

  onSelect(x) {
    console.log("onSelect: ", x);
    this.setState({ inputBuffer: null });
    this.onChange({
      value: x.description,
      valueType: "location",
      valueObj: x,
    });
  }

  render() {
    const {
      state: { suggestions, focused },
    } = this;

    return (
      <>
        {this.renderInputTypes({
          inputProps: {
            onFocus: () => this.setState({ focused: true }),
            onBlur: () => {
              setTimeout(() => {
                this.setState({ focused: false });
              }, 50);
            },
            onChange: (e) => this.handleSearch(e.target.value),
          },
        })}
        {focused && suggestions?.length ? (
          <div key="suggestions">
            <div style={styleObj.holder}>
              {suggestions.map((x) => (
                <div
                  key={x.place_id}
                  style={styleObj.description}
                  onClick={() => this.onSelect(x)}
                >
                  <span style={styleObj.pacIcon} />
                  <span>{x.description}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styleObj = {
  box: {},
  inputs: {
    border: "0",
    background: "0",
    color: "#848484",
    width: "100%",
    fontSize: "13px",
  },
  holder: {
    position: "absolute",
    margin: "6px 0",
    backgroundColor: "#d8eeff",
    fontSize: "smaller",
    // width: "180px",
    boxShadow: "grey 0px 2px 5px 1px",
    zIndex: 2,
  },
  description: {
    border: "solid 0.5px gray",
    padding: "5px 0px 5px 4px",
    cursor: "pointer",
    color: "black",
  },
  pacIcon: {
    padding: "3px 9px",
    width: "15px",
    height: "20px",
    background:
      "url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png)",
    backgroundPosition: "-1px -160px",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
};

export default RenderLocationInput;
