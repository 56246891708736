import React from "react";

import api from "../../../../Services/Api/api";
import RenderFileInput from "./RenderFileInput";

class RenderImageInput extends RenderFileInput {
  constructor(props) {
    super(props);
  }

  inputEl = React.createRef(null);

  async selectFile(files) {
    try {
      if (!files?.length) return;

      const fileLimit = this.props.activeTab?.inputData?.multiple
        ? parseInt(this.props.activeTab?.inputData?.maximumImageCount || 0) ||
          50
        : 1;

      const resultP = [];
      for (let i = 0; i < Math.min(files.length, fileLimit); i++) {
        const file = files[i];

        const result = api
          .media(
            "v1/file",
            { file },
            {
              onUploadProgress: (x) => console.info("on upload progress: ", x),
            }
          )
          .then((result) =>
            api.getFileLink(result.file, {
              params: { mime_type: result.file?.file_mime_type },
            })
          )
          .then((fullUrl) => ({
            value: fullUrl,
            valueObj: { ...result, uri: fullUrl },
          }));
        resultP.push(result);
      }

      const result = await Promise.all(resultP);
      this.onChange({ value: result, valueObj: result });
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    const style = {
      backgroundColor: "lightgray",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      ...(this.styles?.input || {}),
    };

    const acceptFileType =
      this.props.activeTab?.inputData?.acceptFileType || "photo";
    const acceptString =
      acceptFileType === "mixed"
        ? "image/*,video/*"
        : acceptFileType === "photo"
        ? "image/*"
        : acceptFileType + "/*";

    const label = this.toString(this.valueObj?.label?.value);

    return (
      <>
        <div style={style} onClick={() => this.inputEl.current.click()}>
          {this.renderLabel({}, label || "Upload")}
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          accept={acceptString}
          ref={this.inputEl}
          defaultValue={""}
          onChange={(e) => this.selectFile(this.selectFile(e.target.files))}
          multiple={!!this.props.activeTab?.inputData?.multiple}
          max={
            this.props.activeTab?.inputData?.multiple
              ? parseInt(
                  this.props.activeTab?.inputData?.maximumImageCount || 0
                )
              : 1
          }
          min={
            this.props.activeTab?.inputData?.multiple
              ? parseInt(
                  this.props.activeTab?.inputData?.minimumImageCount || 0
                )
              : 1
          }
        />
      </>
    );
  }
}

export default RenderImageInput;
