// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB568GYC76pjVt8qsGoTR5Yqj9P3xAz6lU",
    authDomain: "appxolo-preview.firebaseapp.com",
    projectId: "appxolo-preview",
    storageBucket: "appxolo-preview.appspot.com",
    messagingSenderId: "1077379110419",
    appId: "1:1077379110419:web:4c1b2489156e04727191e6"
};

let app, messaging;

// Initialize Firebase
const init = () => {
  try {
    app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
  } catch (e) {
    console.error("Error initializing firebase: ", e);
  }
};
init()


export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BN4v7tDnG1rCzSzCIzFtOUm9vi6pnNuPcww6JKEscyMLdshhDbw8WU61nogFwcclXeh_Dd50S3JJRkZHVrGSE2g",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        return currentToken
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return null
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });

