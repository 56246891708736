import { useEffect, useRef } from "react";

const useResize = (callback) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            callback(elementRef, entries[0].contentRect);
        });

        if (elementRef.current) {
            resizeObserver.observe(elementRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef, callback]);

    return elementRef;
};

export default useResize;
