import React from "react";
import _ from "lodash";
import RsuiteDateRangePicker from "rsuite/DateRangePicker";
import RsuiteDatePicker from "rsuite/DatePicker";

import RenderInputBase from "./RenderInputBase";
import Calendar from "../../../../Components/Calendar/Calendar";
import utils from "../../../../Components/Calendar/utils";

import WojtekmajTimeRangePicker from "@wojtekmaj/react-timerange-picker";
import WojtekmajTimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";

class RenderDateTimePicker extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  get pickerConfig() {
    return {
      ...(this.props.activeTab?.inputData?.evaluatedDatetimePicker || {}),
    };
  }

  render() {
    const pickerConfig = this.pickerConfig;

    const commonProps = {
      ...this.props,
      pickerConfig,
      value: this.valueObj,
      onChange: this.onChange.bind(this),
      style: this.styles?.input,
    };

    switch (pickerConfig.datepickerMode) {
      case "datetimepicker":
        return <DatetimePicker {...commonProps} />;
      case "timepicker":
        return <TimePicker {...commonProps} />;

      case "datepicker":
      default:
        return <DatePicker {...commonProps} />;
    }
  }
}

class DatePicker extends React.Component {
  onChange(value) {
    const valueObj = {
      ...(this.props.value?.valueObj || {}),
      valueType: this.props.pickerConfig.datepickerMode,
      pickerConfig: this.props.pickerConfig,
      ...value,
    };
    console.log({ value, valueObj });

    this.props.onChange({
      ...this.props.value,
      valueObj,
      value:
        this.props.pickerConfig.selectType === "rangeSelect"
          ? [
              (valueObj.rangeStart || "").toString(),
              (valueObj.rangeEnd || "").toString(),
            ].join()
          : valueObj.value,
    });
  }

  render() {
    return (
      <Calendar
        {...{
          ...this.props,
          pickerConfig: this.props.pickerConfig,
          value: this.props.value?.valueObj,
          onChange: (value) => this.onChange(value),
        }}
      />
    );
  }
}

class DatetimePicker extends DatePicker {
  isDisabledDate(date) {
    const ms = date.getTime();
    const { minDate, maxDate, disabledDates, allowedDates } =
      this.props.pickerConfig || {};

    if ((minDate || minDate === 0) && ms < minDate) return true;
    if ((maxDate || maxDate === 0) && ms > maxDate) return true;
    if (disabledDates?.includes(ms) && !allowedDates?.includes(ms)) return true;

    return false;
  }

  render() {
    const valueObj = this.props.value?.valueObj || {};
    if (this.props.pickerConfig.selectType === "rangeSelect") {
      const rangeStart = utils.parseDate(valueObj?.rangeStart);
      const rangeEnd = utils.parseDate(valueObj?.rangeEnd);
      const value = rangeStart && rangeEnd ? [rangeStart, rangeEnd] : [];
      return (
        <RsuiteDateRangePicker
          style={this.props.style}
          value={value}
          onChange={([rangeStart, rangeEnd]) =>
            this.onChange({
              rangeStart: utils.formatDateToYYYYMMDDHHMM(rangeStart),
              rangeEnd: utils.formatDateToYYYYMMDDHHMM(rangeEnd),
            })
          }
          ranges={[]}
          format="yyyy-MM-dd HH:mm:ss"
          shouldDisableDate={this.isDisabledDate.bind(this)}
        />
      );
    } else {
      return (
        <RsuiteDatePicker
          style={this.props.style}
          value={utils.parseDate(valueObj?.value)}
          onChange={(value) =>
            this.onChange({ value: utils.formatDateToYYYYMMDDHHMM(value) })
          }
          format="yyyy-MM-dd HH:mm:ss"
          shouldDisableDate={this.isDisabledDate.bind(this)}
        />
      );
    }
  }
}

class TimePicker extends DatePicker {
  state = { tempValue: null };

  formRef0 = React.createRef(null);
  formRef1 = React.createRef(null);

  render() {
    const valueObj = this.props.value?.valueObj || {};
    const { minTime, maxTime } = this.props.pickerConfig || {};

    if (this.props.pickerConfig.selectType === "rangeSelect") {
      const rangeStart = utils.parseDate(valueObj?.rangeStart);
      const rangeEnd = utils.parseDate(valueObj?.rangeEnd);

      return (
        <div style={this.props.style}>
          <WojtekmajTimeRangePicker
            value={[rangeStart, rangeEnd]}
            onChange={([rangeStart, rangeEnd]) => {
              this.onChange({
                rangeStart: utils.formatDateToHHMM(
                  utils.parseDate(rangeStart || valueObj.rangeStart)
                ),
                rangeEnd: utils.formatDateToHHMM(
                  utils.parseDate(rangeEnd || valueObj.rangeEnd)
                ),
              });
            }}
            format="hh:mm a"
            maxTime={
              utils.parseDate(maxTime)
                ? utils.formatDateToHHMMSS(utils.parseDate(maxTime))
                : null
            }
            minTime={
              utils.parseDate(minTime)
                ? utils.formatDateToHHMMSS(utils.parseDate(minTime))
                : null
            }
          />
        </div>
      );
    } else {
      return (
        <div style={this.props.style}>
          <WojtekmajTimePicker
            value={utils.parseDate(valueObj?.value)}
            onChange={(value) => {
              if (value) this.onChange({ value: value });
            }}
            format="hh:mm a"
            maxTime={
              utils.parseDate(maxTime)
                ? utils.formatDateToHHMMSS(utils.parseDate(maxTime))
                : null
            }
            minTime={
              utils.parseDate(minTime)
                ? utils.formatDateToHHMMSS(utils.parseDate(minTime))
                : null
            }
          />
        </div>
      );
    }
  }
}

export default RenderDateTimePicker;
