import React from "react";

import RenderTextInput from "./RenderTextInput";

class RenderSelectBoxInput extends RenderTextInput {
  state = {
    visible: false,
  };

  renderInput(props = {}) {
    const selectedValue = this.toString(this.valueObj?.selected?.value);
    const options = this.valueObj?.options?.value;

    return (
      <select
        {...{
          value: selectedValue,
          style: this.styles?.input,
          ...props,
          onChange: (e) =>
            this.onChange({
              selected: { value: e.target.value },
              value: e.target.value,
            }),
        }}
      >
        <option value="">Select</option>
        {options?.map?.((item) => (
          <option
            key={item.uid}
            label={item.label || item.value}
            value={item.value}
          />
        ))}
      </select>
    );
  }
}

export default RenderSelectBoxInput;
