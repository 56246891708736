import React from "react";

import RenderInputBase from "./RenderInputBase";

class RenderRadio extends RenderInputBase {
  render() {
    const selectedValue = this.toString(this.valueObj?.selected?.value);
    const options = this.valueObj?.options?.value;

    return options?.map((item = {}) => (
      <label style={this.styles.label} key={item.value}>
        <input
          {...{
            type: "radio",
            style: this.styles.input,
            checked: item.value === selectedValue,
            value: item.value,
            onChange: (e) =>
              this.onChange({
                selected: { value: item.value },
                value: item.value,
              }),
          }}
        />
        {item.label || item.value || ""}
      </label>
    ));
  }
}

export default RenderRadio;
