import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import RootScreen from "./Views/Root/RootScreen";

import "bootstrap/dist/css/bootstrap.min.css";

import 'rsuite/dist/rsuite.min.css';

import { persistor, store } from "./store";
export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootScreen />
        </PersistGate>
      </Provider>
    );
  }
}
