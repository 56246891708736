import React from 'react';

import VideoMedia from './VideoMedia';
import AudioMedia from './AudioMedia';
import ChartMedia from './ChartMedia';
import WebrtcMedia from './WebrtcMedia';

class RenderMedia extends React.Component {
  mediaTypes = {
    video: props => <VideoMedia {...props} />,
    audio: props => <AudioMedia {...props} />,
    chart: props => <ChartMedia {...props} />,
    webrtc: props => <WebrtcMedia {...props} />,
  };

  render() {
    const {
      props: {activeTab},
    } = this;

    const mediaType = activeTab?.mediaData?.mediaType || 'video';

    return this.mediaTypes[mediaType]?.(this.props);
  }
}

export default RenderMedia;
