import createDatabaseInstance from '../Stores/database/database';

let databases = {};

export const setDatabases = x => (databases = x);
export const getDatabases = () => databases;

export const getDatabase = async dbId => {
  let database = databases[dbId];

  if (!database) {
    database = databases[dbId] = await createDatabaseInstance(dbId);
  }

  return database;
};
