import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { useSwipeable } from "react-swipeable";
import useResize from "../etc/useResize";

export const CarouselItem = ({ children, index, active, onRender }) => {
  const elRef = useResize((elRef) => {
    onRender(()=>({
        clientHeight: elRef?.current?.clientHeight,
        clientWidth: elRef?.current?.clientWidth,
    }));
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     onRender(getLayout);
  //   }, 100);
  // }, []);

  const getLayout = () => ({
    clientHeight: elRef?.current?.clientHeight,
    clientWidth: elRef?.current?.clientWidth,
  });

  return (
    <div
      ref={elRef}
      style={{
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: active ? "green" : "#fff0",
      }}
      onResize={() => onRender(getLayout)}
      onLoad={() => onRender(getLayout)}
    >
      {children}
    </div>
  );
};

class Carousel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateIndex = this.updateIndex.bind(this);
  }

  state = {
    activeIndex: 0,
    pause: false,
  };

  childData = [];
  elRef = React.createRef(null);
  autoplayTimer = null;

  componentDidMount() {
    if (this.props.config?.autoPlay) {
      this.autoplayTimer = setInterval(() => {
        if (!this.state.pause) this.goToNext();
      }, parseInt(this.props.config?.autoPlayInterval || 1000));
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoplayTimer);
  }

  updateIndex(newIndex) {
    const { children, config } = this.props;
    if (newIndex < 0) {
      newIndex = config?.loop ? React.Children.count(children) - 1 : 0;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = config?.loop ? 0 : React.Children.count(children) - 1;
    }

    this.setState({ activeIndex: newIndex });
  }

  onChildRender(index, x, isVertical, rowIndex) {
    this.childData = update(this.childData, {
      $merge: {
        [index]: update(this.childData[index] || [], {
          $merge: {
            [rowIndex || 0]: x(),//[isVertical ? "clientHeight" : "clientWidth"],
          },
        }),
      },
    });
  }

  getTranslateSize(index, { childData, key }) {
    let relativeValue = 0;
    if (
      this.state.dragging &&
      this.state.dragStart &&
      this.state.dragPosition
    ) {
      const relativePosition = {
        clientX:
          this.state.dragPosition?.clientX - this.state.dragStart?.clientX,
        clientY:
          this.state.dragPosition?.clientY - this.state.dragStart?.clientY,
      };
      const dict = { clientWidth: "clientX", clientHeight: "clientY" };
      const value = relativePosition[dict[key]];
      if (!isNaN(value)) relativeValue = parseInt(value * -1);
    }

    const dict = { clientWidth: "scrollWidth", clientHeight: "scrollHeight" };
    let total = 0;
    for (let i = 0; i < index; i++) {
      const rows = childData[i];

      for (let j = 0; j < rows?.length; j++) {
        const element = rows[j];
        total += element?.[key] || 0;
      }
    }

    let sliderEl = this.elRef.current || {};

    total = Math.max(
      0,
      Math.min(total, sliderEl[dict[key]] - sliderEl.parentElement?.[key])
    );

    total = total + relativeValue;

    return total * -1 + "px";
  }

  goToNext() {
    this.updateIndex(
      this.state.activeIndex + parseInt(this.props.config?.scrollCount || 1)
    );
  }
  goToPrev() {
    this.updateIndex(
      this.state.activeIndex - parseInt(this.props.config?.scrollCount || 1)
    );
  }

  handleDrag(e) {
    this.setState({ dragPosition: e });
  }

  getSnapPoints(childData, { isVertical }) {
    const key = isVertical ? "clientHeight" : "clientWidth";

    let arr = [0];
    let total = 0;
    for (let i = 0; i < childData.length; i++) {
      const rows = childData[i];

      for (let j = 0; j < rows?.length; j++) {
        const element = rows[j];
        total += element?.[key] || 0;
        arr.push(total);
      }
    }
    return arr;
  }

  getClosestIndex({ offset, isVertical }) {
    const snapPoints = [
      -Infinity,
      ...this.getSnapPoints(this.childData, { isVertical }),
      Infinity,
    ];

    const currentIndex = this.state.activeIndex;
    let snapPosition = snapPoints[currentIndex + 1] || 0;

    let sliderEl = this.elRef.current || {};
    snapPosition = Math.max(
      0,
      Math.min(
        snapPosition,
        sliderEl[isVertical ? "scrollHeight" : "scrollWidth"] -
          sliderEl.parentElement?.[isVertical ? "clientHeight" : "clientWidth"]
      )
    );

    let position = snapPosition + offset;

    // let i = snapPoints.findIndex((x, i) => {
    //   if (x === position) {
    //     return true;
    //   } else if (
    //     i + 1 < snapPoints.length &&
    //     snapPoints[i] < position &&
    //     snapPoints[i + 1] > position
    //   ) {
    //     // find closest
    //   } else {
    //   }
    // });

    let i = 0;
    while (position > snapPoints[i]) {
      ++i;
    }

    if (
      position > -50 &&
      position - snapPoints[i - 1] > snapPoints[i] - position
    )
      ++i;

    let index = i - 2;

    return index;
  }

  handleDragEnd(dragPosition) {
    const relativePosition = {
      clientX: this.state.dragStart?.clientX - dragPosition?.clientX,
      clientY: this.state.dragStart?.clientY - dragPosition?.clientY,
    };

    const isVertical = this.props.style?.flexDirection === "column";

    const offset = relativePosition[isVertical ? "clientY" : "clientX"];
    const closestIndex = this.getClosestIndex({ offset, isVertical });

    this.setState(
      { dragging: false, dragStart: null, dragPosition: null },
      () => {
        setTimeout(() => {
          this.updateIndex(closestIndex);
        }, 0);
      }
    );
  }

  render() {
    const {
      state: { activeIndex },
      props: { children, config, style, divProps },
      updateIndex,
      childData,
    } = this;

    const pagination = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: "4px",
          left: 0,
          right: 0,
        }}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <span
              style={{
                display: "inlineBlock",
                padding: "5px",
                marginRight: "10px",
                backgroundColor: index == activeIndex ? "#fff" : "#ccc",
                borderRadius: "15px",
                // boxShadow:
                //   index == activeIndex ? "#c6c6c6 -1px 2px 4px 0px" : "#ffffff -1px 2px 3px 0px",
              }}
              onClick={() => {
                updateIndex(index);
              }}
            ></span>
          );
        })}
      </div>
    );

    const isVertical = style.flexDirection === "column";

    return (
      <div
        style={{
          ...(style || {}),
          overflow: "hidden",
          position: "relative",
          cursor: "grab",
        }}
      >
        <div
          ref={this.elRef}
          style={{
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: isVertical ? "column" : "row",
            transition: this.state.dragging
              ? "transform 0.01s"
              : "transform 0.3s",
            transform: `translate${
              isVertical ? "Y" : "X"
            }(${this.getTranslateSize(activeIndex, {
              childData,
              key: isVertical ? "clientHeight" : "clientWidth",
            })})`,
          }}
          onMouseEnter={() => this.setState({ pause: true })}
          onMouseLeave={(e) => {
            this.setState({ pause: false });
            if (this.state.dragging) this.handleDragEnd(e);
          }}
          onMouseMove={(e) =>
            e.buttons && this.state.dragging ? this.handleDrag(e) : null
          }
          onMouseDown={(e) => this.setState({ dragStart: e, dragging: true })}
          onMouseUp={(e) => {
            if (this.state.dragging) this.handleDragEnd(e);
          }}
        >
          {React.Children.map(children, (child, index) => {
            return {
              ...child,
              props: {
                ...child.props,
                intermediateProps: {
                  mode: "carouselItem",
                  index,
                  onRender: (rowIndex) => (x) =>
                    this.onChildRender(index, x, isVertical, rowIndex),
                  active: index == activeIndex,
                  activeIndex,
                },
              },
            };
          })}
          {/* {React.Children.map(children, (child, index) => {
            return (
              <CarouselItem
                {...{
                  index,
                  onRender: (x) => this.onChildRender(index, x),
                  active: index == activeIndex,
                  activeIndex,
                }}
              >
                {child}
              </CarouselItem>
            );
          })} */}
        </div>
        {config?.pagination ? pagination : null}
      </div>
    );
  }
}

const Swipable = ({
  children,
  style,
  onSwipedLeft,
  onSwipedRight,
  props = {},
}) => {
  const handlers = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  return (
    <div {...handlers} style={style} {...props}>
      {children}
    </div>
  );
};

export default Carousel;
